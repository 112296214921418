<template>
	<div style="background-color: #F8F8F8;height:100vh;">
		<head-bar></head-bar>
		<!-- <div @click="$noMultipleClicks(cheshi)">111111111111</div> -->
		<div>
			<div style="width: 100%;background: #0085FF;">
				<el-row style="width:1200px;margin: 0 auto;">

					<el-col :span="24">
						<div class="fl">
							<div style="height:72px;width:500px;margin-right: 100px;">
								<el-carousel :interval="2000" height="72px" arrow="always">
									<el-carousel-item v-for="item in bannerList" >
										<el-image @click="$noMultipleClicks(toLink1,item)"
											style=" height: 72px;cursor:pointer;" :src="item.pic"
											fit="cover"></el-image>
									</el-carousel-item>
								</el-carousel>
							</div>
							<noticeBar :noticeData="noticeData"></noticeBar>
						</div>
					</el-col>

				</el-row>
			</div>

			<div :style="{ zoom:  getAcale}">
				<el-row class="content">

					<el-col :span="24">
						<div class="chatMain ">
							<div class="chatLeft">
								<div class="fl leftItem" @click="$router.push('/mineIndex/1')">
									<img :src="$store.state.userInfo.portrait || require('@/static/image/index/profile.png')"
										style="width: 48px;height: 48px;border-radius: 50%;" />
									<div class="el1 leftText" style="color: #fff;width:90px;">
										{{$store.state.userInfo.name}}
									</div>
								</div>

								<div class="fl leftItem1" :class="{'leftAction':leftAction == index}"
									v-for="(item,index) in leftList" 
									@click="$noMultipleClicks(leftClick,index)">
									<img :src="leftAction == index ?  item.actionImage : item.image "
										style="width: 30px;height: 30px;" />
									<div class="el1 leftText">{{item.text}}</div>
								</div>

								<div class="fl leftItem1" v-if="!isWarning" @click="$noMultipleClicks(setWarning,true)">
									<img :src="require('@/static/image/chat/chat7.png')"
										style="width: 30px;height: 30px;" />
									<div class="el1 leftText" style="color: #fff;">提示音</div>
								</div>
								<div class="fl leftItem1" v-else @click="$noMultipleClicks(setWarning,false)">
									<img :src="require('@/static/image/chat/chat14.png')"
										style="width: 30px;height: 30px;" />
									<div class="el1 leftText" style="color: #19D972;">提示音</div>
								</div>
							</div>

							<div class="account" v-if="leftAction == 7">

								<div class="chatList" @scroll="addOrder">
									<div class="fl chatItem" :class="{'chatActions': item.id == singleChatInfo.id }"
										v-for="item in recordList" @click="$noMultipleClicks(singleChat,item)">
										<div style="position: relative;overflow: hidden;">
											<img :src="user == item.userId ?   item.portrait : item.portraitConsult "
												style="width: 80px;height: 80px;border-radius: 12px;" />
										</div>
										<div style="margin-left: 12px;">
											<div class="infoTitle el2 ">
												<span v-show="item.type == '2'" class="infoAccount"
													style="background-color: #B742FF;">回收</span>
												<span v-show="item.type == '3' " class="infoAccount">代练</span>
												<span
													style="margin-left: 5px;">{{user == item.userId ?   item.name : item.nameConsult }}</span>
											</div>
											<div class="idItem">
												<span>创建时间:{{item.createTime}}</span>
											</div>
											<!-- <div style="width: 200px;" class="idItem el1">
												<span>{{item.tradeMessage}}</span>
											</div> -->
										</div>

										<div style="position: absolute;top: 10px;right: 30px;">
											<el-badge
												:value="getSingleUnReads(user == item.userId ?   item.consultId : item.userId)"
												class="item">
												<div style="color: #FF5C00;"></div>
											</el-badge>
										</div>
									</div>
								</div>
							</div>



							<div class="account" v-else-if="leftAction != 6">
								<div class="search">
									<div class="searchInfo">
										<el-input v-prevent-space v-model="search.id"
											style="width: 348px;margin: 12px 0;" placeholder="搜索订单编号" clearable
											@focus="searchFocus" @blur="searchBlur ">
											<i slot="prefix" class="el-input__icon el-icon-search"></i>
										</el-input>
									</div>
								</div>
								<div class="chatList " @scroll="addOrder">
									<div class="fl chatItem " :class="{'chatActions': item.id == userInfo.id }"
										v-for="(item,index) in tableData" @click="selectOrder(item,index)">
										<div style="position: relative;overflow: hidden;">
											<img :src="item.icon"
												style="width: 80px;height: 80px;border-radius: 12px;" />
											<span v-if="item.isHot == 1" class="chatStatus1">热卖</span>
											<span v-else-if="item.orderType == 3" class="chatStatus1">收货</span>
											<span
												v-else-if="item.gameCommodityCategory == 2 || item.gameCommodityCategory == 8"
												class="chatStatus1">代练</span>
											<span
												v-else-if="item.gameCommodityCategory == 5 || item.gameCommodityCategory== 6 "
												class="chatStatus1">账号</span>
											<!--  -->
											<span v-else-if="item.tradeType == 2" class="chatStatus1">拍卖</span>
											<span class="chatStatus" v-if="item.status == -1">已取消</span>
											<span class="chatStatus" v-if="item.status == -2">已拒绝</span>
											<span class="chatStatus" v-if="item.status == 0">待支付</span>
											<span class="chatStatus"
												v-if="item.status == 1 && item.reasonType == null">待发货</span>
											<span class="chatStatus"
												v-if="item.status == 1 && item.reasonType != null">申请取消</span>
											<span class="chatStatus" v-if="item.status == 2">待确认</span>
											<span class="chatStatus" v-if="item.status == 3">待收货</span>
											<span class="chatStatus" v-if="item.status == 4">交易成功</span>
											<span class="chatStatus" v-if="item.status == 5">售后中</span>
											<span class="chatStatus" v-if="item.status == 6">售后完成</span>
											<span class="chatStatus" v-if="item.status == 7">已退款</span>
										</div>
										<div style="margin-left: 12px;">
											<div class="infoTitle el1 ">
												<span
													v-if="item.gameCommodityCategory == 5 || item.gameCommodityCategory == 6"
													class="infoAccount">账号</span>
												<span v-else-if="item.deliveryType == 2" class="infoAccount"
													style="background-color: #B742FF;">寄售</span>

												<span v-else class="infoAccount"
													style="background-color: #19D972;">担保</span>

												<span style="margin-left: 5px;">{{item.id}}</span>

											</div>
											<div v-if="item.gameCommodityCategory == 1 || item.gameCommodityCategory == 12"
												class="idItem el1" style="width:180px">
												<span
													v-if="item.deliveryType == 3 || item.deliveryType == 5 ">1件={{item.num }}{{item.commodityUnit}}</span>
												<span
													v-else>{{item.num}}件={{ item.num * item.piecesNum }}{{item.commodityUnit}}</span>
											</div>
											<div v-else class="idItem el1" style="width:180px">
												<span>{{item.title}}</span>
											</div>
											<div v-if="item.districtName !='' && item.districtName !=null"
												style="width: 200px;" class="idItem el1">
												<span>{{item.districtName}}</span>
											</div>
											<div style="width: 200px;" class="idItem el1">
												<span>{{item.tradeMessage}}</span>
											</div>
										</div>

										<div style="position: absolute;top: 10px;right: 30px;">
											<el-badge :value="getUnRead(item.chatroomId)" class="item">
												<div style="color: #FF5C00;"></div>
											</el-badge>
										</div>
										<div v-if="leftAction == 0 " class="chatClose"
											@click.stop="closeChat(item,index)">
											<img :src="require('@/static/image/index/close1.png')"
												style="width: 20px;height: 20px;" />
										</div>
									</div>
								</div>
							</div>

							<div v-if="Object.keys(singleChatInfo).length !=0 && leftAction == 7 "
								style="display: flex;">
								<div class="chat" style="width: 1144px;">
									<div class="allMore" @click="chatCenter">点击查看更多</div>
									<!-- @scroll="chatCenter" -->
									<div class="chatCenter" ref="chatCenter" >
										<div v-for="(item,index) in messageList">
											<div class="recordsLeft" v-if="item.from != user">
												<!-- <img :src="require('@/static/image/index/profile.png')"
													style="width: 40px;height: 40px;border-radius: 50%;" /> -->
												<div class="leftInfo">
													<img v-if="item.profile != undefined"
														style="width: 50px;height: 50px;border-radius: 50%;margin-right: 8px;"
														:src="item.profile" alt="" />
													<div>
														<span class="infoName">对方</span>
													</div>
													<div class="infoCount" style="white-space: pre-wrap;"
														v-html="renderTxt(item.msg)" v-if="item.type == 'txt'">
													</div>
													<div class="infoCount" v-if="item.type == 'img'">
														<el-image :preview-src-list="[item.url]"
															style="width: 200px;" :src="item.url"
															fit="cover"></el-image>
													</div>
													<div class="infoCount" v-if="item.type == 'video'">
														<video width="300" controls :src="item.url"></video>
													</div>
												</div>
											</div>
											<div class="recordsRight" v-else>
												<div class="rightInfo">
													<div style="text-align: right;">
														<span class="infoTime1" style="margin-right: 10px;">我</span>
														<span class="infoName">{{sjVal(item.time,2)}}</span>
													</div>
													<div class="infoCount" style="white-space: pre-wrap;"
														v-html="renderTxt(item.msg)" v-if="item.type == 'txt'">
														<!-- {{item.msg}} -->
													</div>
													<div class="infoCount" v-if="item.type == 'img'">
														<el-image :preview-src-list="[item.url]"
															style="width: 200px;" :src="item.url"
															fit="cover"></el-image>
													</div>
													<div class="infoCount" v-if="item.type == 'video'">
														<video width="300" controls :src="item.url"></video>
													</div>
												</div>
												<!-- <img :src="require('@/static/image/index/profile.png')"
													style="width: 40px;height: 40px;border-radius: 50%;" /> -->
											</div>
										</div>


									</div>

									<div class="chatBottom" style="width: 1144px;">
										<div class="bottomTop">
											<div class="bottomLeft" ref="dialogBox">

												<div v-if="emojiShow">
													<transition name="el-fade-in-linear">
														<div class="emoji">
															<img v-for="(v,i) in emojiList"
																:src="require(`@/static/faces/${v}`)" :key="i"
																@click="selectEmoji(i)" class="img-style" />
														</div>
													</transition>
												</div>

												<img @click="emojiShow = true"
													:src="require('@/static/image/chat/function1.png')"
													class="functionImage" />

												<!-- 	<img @click="snipping" :src="require('@/static/image/chat/function2.png')"
													class="functionImage" /> -->
												<img @click="selectImage"
													:src="require('@/static/image/chat/function3.png')"
													class="functionImage" />
												<img @click="selectVideo"
													:src="require('@/static/image/chat/function4.png')"
													class="functionImage" />
											</div>

										</div>

										<div>
											<textarea v-model="chatValue" contenteditable="true" ref="pasteContent"
												@paste="pasteImg" class="textareaSty" type="textarea" />
											<div style="float: right;"><el-button
													@click="$noMultipleClicks(sendSingleMessage)"
													type="primary">发送</el-button></div>
										</div>
									</div>
								</div>

							</div>
							<!--  -->
							<div v-if="Object.keys(userInfo).length !=0 && leftAction != 6 " style="display: flex;">
								<div class="chat" :class="{'HandleDrag':isHandleDrag}">
									<div class="chatTop">
										<div @click="roleIm = 'user';getHistoryMessages(userInfo.chatroomId,'groupChat')"
											:class="{'chatAction':roleIm == 'user'}" class="chatTopItem"
											v-if="Object.keys(userInfo).length != 0">
											<el-badge class="item">
												<span style="margin-left: 12px;">订单聊天</span>
											</el-badge>
										</div>
										<!-- <div @click="roleIm = 'service';getHistoryMessages(serveInfo.id,'singleChat')"
											:class="{'chatAction':roleIm == 'service'}" class="chatTopItem"
											v-if="Object.keys(serveInfo).length != 0">
											<el-badge :value="getUnRead(serveInfo.id)" class="item">
												<span style="margin-left: 12px;color: #FF5C00;">客服</span>
											</el-badge>
										</div> -->
									</div>
									<div class="chatCenter" ref="chatCenter" >
										<!-- @scroll="chatCenter" -->
										<div class="allMore" @click="chatCenter">点击查看更多</div>
										<div v-for="(item,index) in messageList">
											<div class="recordsLeft" v-if="item.from != user">
												<!-- <img :src="require('@/static/image/index/profile.png')"
													style="width: 40px;height: 40px;border-radius: 50%;" /> -->
												<div class="leftInfo">

													<div class="fl">
														<img v-if="item.profile != undefined"
															style="width: 50px;height: 50px;border-radius: 50%;margin-right: 8px;"
															:src="item.profile" alt="" />
														<span
															v-if="item.from == userInfo.userMessageId && (userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8)"
															class="infoName">代练方</span>
														<span v-else-if="item.from == userInfo.userMessageId "
															class="infoName">买家</span>

														<span
															v-if="item.from == userInfo.sellerId && (userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8)"
															class="infoName">玩家</span>
														<span v-else-if="item.from == userInfo.sellerId"
															class="infoName">卖家</span>
														<span v-if="item.from == userInfo.robotId"
															class="infoName2">系统消息</span>
														<span v-if="item.from == userInfo.serviceId"
															class="infoName2">交易客服</span>
														<span v-if="item.from == userInfo.afterSellServiceId"
															class="infoName2">售后客服</span>
														<span class="infoTime">{{sjVal(item.time,2)}}</span>
													</div>
													<div class="infoCount" style="white-space: pre-wrap;"
														:class="{ 'mag50' : item.profile != undefined}"
														v-html="renderTxt(item.msg)" v-if="item.type == 'txt'">

													</div>
													<div class="infoCount" v-if="item.type == 'img'"
														:class="{ 'mag50' : item.profile != undefined}">
														<el-image :preview-src-list="[item.url]"
															style="width: 200px;" :src="item.url"
															fit="cover"></el-image>
													</div>
													<div class="infoCount" v-if="item.type == 'video'"
														:class="{ 'mag50' : item.profile != undefined}">
														<video width="300" controls :src="item.url"></video>
													</div>
												</div>
											</div>
											<div class="recordsRight" v-else>
												<div class="rightInfo">
													<div style="text-align: right;">
														<span class="infoTime1" style="margin-right: 10px;">我</span>
														<span class="infoName">{{sjVal(item.time,2)}}</span>
													</div>
													<div class="infoCount" style="white-space: pre-wrap;"
														v-html="renderTxt(item.msg)" v-if="item.type == 'txt'">
														<!-- {{item.msg}} -->
													</div>
													<div class="infoCount" v-if="item.type == 'img'">
														<el-image :preview-src-list="[item.url]"
															style="width: 200px;" :src="item.url"
															fit="cover"></el-image>
													</div>
													<div class="infoCount" v-if="item.type == 'video'">
														<video width="300" controls :src="item.url"></video>
													</div>
												</div>
												<!-- <img :src="require('@/static/image/index/profile.png')"
													style="width: 40px;height: 40px;border-radius: 50%;" /> -->
											</div>
										</div>


									</div>

									<div class="chatBottom">
										<div class="bottomTop">
											<div class="bottomLeft" ref="dialogBox">

												<div v-if="emojiShow">
													<transition name="el-fade-in-linear">
														<div class="emoji">
															<img v-for="(v,i) in emojiList"
																:src="require(`@/static/faces/${v}`)" :key="i"
																@click="selectEmoji(i)" class="img-style" />
														</div>
													</transition>
												</div>

												<img @click="emojiShow = true"
													:src="require('@/static/image/chat/function1.png')"
													class="functionImage" />

												<!-- 	<img @click="snipping" :src="require('@/static/image/chat/function2.png')"
													class="functionImage" /> -->
												<img @click="selectImage"
													:src="require('@/static/image/chat/function3.png')"
													class="functionImage" />
												<img @click="selectVideo"
													:src="require('@/static/image/chat/function4.png')"
													class="functionImage" />
											</div>
											<!-- <div v-if="userInfo.status == 4 || userInfo.status == 5 || userInfo.status == 6">
												<span class="bottomTips">如订单有疑问，可联系售后客服</span>
												<el-badge value="" class="item" style="margin-left: 30px;">
													<el-button @click="customerService" type="primary"
														plain>申请售后</el-button>
												</el-badge>
											
											</div> -->
											<div
												v-if="userInfo.status != 4 && userInfo.status != 5 && userInfo.status != 6 && userInfo.status != -1">
												<span v-if="userInfo.deliveryType == 3 && userInfo.status == 0"
													class="bottomTips">订单未支付，无法发起会话</span>
												<span v-else class="bottomTips">若需要客服帮助，可点击联系客服介入</span>
												<span v-if="userInfo.deliveryType == 3 && userInfo.status == 0"></span>
												<el-badge v-else value="" class="item" style="margin-left: 30px;">
													<el-button @click="$noMultipleClicks(customerService)"
														type="primary" plain>联系客服</el-button>
												</el-badge>

											</div>
										</div>
										<div v-if="roleIm == 'user'">
											<div class="chatArea "
												v-if="Object.keys(userInfo).length == 0  || userInfo.status == 0 || userInfo.status == -1  || userInfo.status == 4  || userInfo.status == 6"
												style="text-align: center;line-height: 100px; font-size: 14px;">
												<div v-if="userInfo.status == -1">
													<span>订单已取消，无法发起会话。如需帮助，</span>
													<span style="color: #0085FF;"
														@click="$noMultipleClicks(customerService)">联系售后客服</span>
												</div>
												<div v-if="userInfo.status == 0 ">
													<span
														v-if="userInfo.deliveryType == 3">请耐心等待买家支付，如超时支付，系统会自动给你至少10元赔付</span>
													<span v-else>订单未支付，无法发起会话。如需帮助，</span>

													<span v-if="userInfo.deliveryType != 3" style="color: #0085FF;"
														@click="$noMultipleClicks(customerService)">联系咨询客服</span>
												</div>

												<div v-if="userInfo.status == 4">
													<span>订单交易成功，无法发起会话。如需帮助，</span>
													<span @click="$noMultipleClicks(customerService)"
														style="color: #0085FF;">联系售后客服</span>
												</div>
												<div v-if="userInfo.status == 6">
													<span>售后完成，无法发起会话。</span>
												</div>
											</div>
											<div v-else>


												<textarea v-model="chatValue" contenteditable="true" ref="pasteContent"
													@paste="pasteImg" class="textareaSty" type="textarea" />

												<div style="float: right;"><el-button
														@click="$noMultipleClicks(sendMessage)"
														type="primary">发送</el-button></div>

											</div>
										</div>
										<div v-else>
											<textarea v-model="chatValue" contenteditable="true" ref="pasteContent"
												@paste="pasteImg" class="textareaSty" type="textarea" />
											<div style="float: right;"><el-button
													@click="$noMultipleClicks(sendMessage)"
													type="primary">发送</el-button></div>
										</div>
									</div>
								</div>

								<div class="orderAll" v-if="Object.keys(userInfo).length !=0 ">
									<div class="orderTop">
										<div class="fl">
											<img v-if="userInfo.status == -1"
												:src="require('@/static/image/chat/order5.png')" class="orderImage" />
											<img v-if="userInfo.status == 0 || userInfo.status == 1"
												:src="require('@/static/image/chat/order1.png')" class="orderImage" />
											<img v-if=" userInfo.status == 2 ||userInfo.status == 3"
												:src="require('@/static/image/chat/order2.png')" class="orderImage" />
											<img v-if="userInfo.status == 4 || userInfo.status == 6"
												:src="require('@/static/image/chat/order3.png')" class="orderImage" />
											<img v-if="userInfo.status == 5"
												:src="require('@/static/image/chat/order4.png')" class="orderImage" />

											<!-- 处理订单状态 userInfo.userMessageId == user是买家  -->
											<div class="orderState"
												v-if="userInfo.status == 1 && userInfo.reasonType != null">
												<span class="orderTitle">申请取消</span>
												<span class="orderDetaile">取消原因:{{userInfo.applyReason}}</span>
											</div>
											<div class="orderState"
												v-if="userInfo.status == -1 && userInfo.userMessageId == user">
												<span class="orderTitle">已取消</span>
												<span class="orderDetaile">{{userInfo.cancelReason}}</span>
											</div>
											<div class="orderState"
												v-if="userInfo.status == -1 && userInfo.sellerId == user">
												<span class="orderTitle">已取消</span>
												<span class="orderDetaile">{{userInfo.cancelReason}}</span>
											</div>
											<div class="orderState" style="position: relative;"
												v-if="userInfo.status == 0 && userInfo.userMessageId == user">
												<span class="orderTitle">待支付</span>
												<span class="orderDetaile">您还未支付~</span>

												<div class="payOvertime">
													<span v-if="totalSeconds != 0 || totalSeconds != ''">
														支付剩余:{{ minutes }} 分{{ seconds }} 秒
													</span>
												</div>
											</div>
											<div class="orderState" style="position: relative;"
												v-if="userInfo.status == 0 && userInfo.sellerId == user">
												<span class="orderTitle">未支付</span>
												<span class="orderDetaile">买家还未支付订单，请稍后～</span>

												<div class="payOvertime">
													<span v-if="totalSeconds != 0 || totalSeconds != ''">
														支付剩余:{{ minutes }} 分{{ seconds }} 秒
													</span>
												</div>
											</div>
											<div
												v-if="userInfo.deliveryType == 2 && ( userInfo.status == 1 || userInfo.status == 2 || userInfo.status == 3)">
												<div class="orderState">
													<span class="orderTitle">处理中</span>
													<span class="orderDetaile">感谢等待，客服正在登录游戏为您发货！</span>


												</div>
											</div>
											<div v-else>
												<div class="orderState"
													v-if="userInfo.status == 1  && userInfo.reasonType == null">
													<span
														v-if="userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8"
														class="orderTitle">未开始</span>
													<span v-else class="orderTitle">待发货</span>
													<span
														v-if="userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8"
														class="orderDetaile">您的代练订单暂未开始，请稍后～</span>
													<span v-else class="orderDetaile">您的订单正在出货中，请稍后～</span>
												</div>
												<div class="orderState" v-if="userInfo.status == 2 ">
													<span
														v-if="userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8"
														class="orderTitle">代练中</span>
													<span
														v-if="userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8"
														class="orderDetaile">您的订单正在代练中，请稍后～</span>

												</div>
												<div class="orderState" style="position: relative;"
													v-if="userInfo.status == 3 ">
													<span
														v-if="userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8"
														class="orderTitle">待确认</span>
													<span v-else class="orderTitle">待收货</span>
													<span
														v-if="userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8"
														class="orderDetaile">您的订单正在等待确认，请稍后～</span>
													<span v-else class="orderDetaile">请您确认收货结果，如有问题及时申请客服介入</span>


												</div>
											</div>
											<div class="orderState" v-if="userInfo.status == 4">
												<span
													v-if="userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8"
													class="orderTitle">代练完成</span>
												<span v-else-if="userInfo.settleType  == 2"
													class="orderTitle">部分结算</span>
												<span v-else-if="userInfo.settleType  == 1"
													class="orderTitle">全部结算</span>
												<span v-else="userInfo.settleType  == null"
													class="orderTitle">交易成功</span>

												<span
													v-if="userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8"
													class="orderDetaile">您的代练订单已完成，感谢您的信任</span>
												<span v-else-if="userInfo.settleType  == 2"
													class="orderDetaile">买卖双方通过协商，一致同意部分交易完成。</span>

												<span v-else-if="userInfo.settleType  == 1"
													class="orderDetaile">买卖双方通过协商，一致同意全部结算。</span>

												<span v-else class="orderDetaile">您的订单已确认收货，感谢您的信任</span>
											</div>


											<div class="orderState" v-if="userInfo.status == 5">
												<span class="orderTitle">售后中</span>
											</div>
											<div class="orderState" v-if="userInfo.status == 6">
												<span class="orderTitle">售后完成</span>
											</div>
										</div>





										<!-- 收货取消订单  || userInfo.status == 1 -->
										<div
											v-if="userInfo.deliveryType == 2 && ( userInfo.status == 1 || userInfo.status == 2 || userInfo.status == 3)">
										</div>
										<div v-else>
											<div class="stateList"
												v-if="userInfo.status == 1 && userInfo.userMessageId == user && userInfo.reasonType == null">
												<span class="state1" @click="setConfirm('查看账号','account')"
													v-if="(userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8) && userInfo.receiveRole != null">查看账号</span>
												<span
													v-if="userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8 "
													class="state2" @click="deliver1(2)">开始代练</span>


												<div v-else class="state1" @click="remind(3)">催促发货
													<span v-if="totalSeconds != 0 || totalSeconds != ''">
														{{ minutes }} 分{{ seconds }} 秒
													</span>
												</div>

												<span v-if="userInfo.deliveryType != '2'" class="cancelState"
													@click="$noMultipleClicks(cancelOrder)">取消订单</span>
											</div>
											<div class="stateList"
												v-if="userInfo.status == 1 && userInfo.sellerId == user && userInfo.reasonType == null && userInfo.gameCommodityCategory != 5 &&  userInfo.gameCommodityCategory != 6">
												<div v-if=" userInfo.gameCommodityCategory == 8 || userInfo.gameCommodityCategory == 2"
													class="state2" @click="$noMultipleClicks(remind,3)">催促代练方
													<span v-if="totalSeconds != 0 || totalSeconds != ''">
														{{ minutes }} 分{{ seconds }} 秒
													</span>
												</div>
												<span
													v-if="userInfo.gameCommodityCategory != 8 &&  userInfo.gameCommodityCategory != 2 && userInfo.deliveryType != '2'"
													class="state2" @click="$noMultipleClicks(deliver,3)">发货完成</span>
												<span class="cancelState" v-if="userInfo.deliveryType != '2'"
													@click="$noMultipleClicks(cancelOrder)">取消订单</span>
											</div>

											<div class="stateList"
												v-if="userInfo.status == 2 && userInfo.userMessageId == user && userInfo.reasonType == null">
												<span class="state1" @click="setConfirm('查看账号','account')"
													v-if="userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8 ">查看账号</span>
												<span class="state2" @click="$noMultipleClicks(deliver1,3)">代练结束</span>
												<span class="cancelState"
													@click="$noMultipleClicks(customerService)">申请客服介入</span>
											</div>
											<div class="stateList"
												v-if="userInfo.status == 2 && userInfo.sellerId == user && userInfo.reasonType == null && userInfo.gameCommodityCategory != 5 &&  userInfo.gameCommodityCategory != 6">

												<span class="cancelState"
													@click="$noMultipleClicks(customerService)">申请客服介入</span>
											</div>
											<div class="stateList"
												v-if="userInfo.status == 3 && userInfo.userMessageId == user">
												<div v-if="userInfo.serviceId == null || userInfo.serviceId == ''">
													<div v-if="totalSeconds1 != 0 || totalSeconds1 != ''">
														<span>
															还剩:
														</span>
														<span style="color: #FF5C00;">{{ minutes1 }}
															分{{ seconds1 }}秒</span>
														<span
															v-if=" userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8">自动代练完成</span>
														<span v-else>自动确认收货您可以</span>
													</div>
												</div>
												<div v-else>
													<span>确认收到货后您可以点击</span>

												</div>

												<div v-if=" userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8"
													class="state1" @click="$noMultipleClicks(remind,2)">提醒代练完成
													<span v-if="totalSeconds != 0 || totalSeconds != ''">
														{{ minutes }} 分{{ seconds }} 秒
													</span>
												</div>
												<span v-else class="state1"
													@click="setConfirm('确认收货','completedOrder')">确认收货</span>
												<span class="cancelState" @click="customerService">申请客服介入</span>
											</div>
											<div class="stateList"
												v-if="userInfo.status == 3 && userInfo.sellerId == user">
												<div v-if="userInfo.serviceId == null || userInfo.serviceId == ''">
													<div v-if="totalSeconds1 != 0 || totalSeconds1 != ''">
														<span>
															还剩:
														</span>
														<span style="color: #FF5C00;">{{ minutes1 }}
															分{{ seconds1 }}秒</span>
														<span
															v-if=" userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8">自动代练完成</span>
														<span v-else>自动确认收货您可以</span>
													</div>
												</div>

												<div v-else>
													<span>客服介入，等待买家确认收货，您可以</span>
												</div>
												<span
													v-if=" userInfo.gameCommodityCategory == 2 || userInfo.gameCommodityCategory == 8"
													class="state1"
													@click="setConfirm('代练结束','completedOrder')">确认收货</span>
												<div v-else class="state2" @click="$noMultipleClicks(remind,2)">提醒收货
													<span v-if="totalSeconds != 0 || totalSeconds != ''">
														{{ minutes }} 分{{ seconds }} 秒
													</span>
												</div>
												<span class="cancelState" @click="customerService">申请客服介入</span>
											</div>

										</div>
										<div class="stateList"
											v-if="(userInfo.status == 0 )  && userInfo.sellerId == user  && userInfo.orderType == 3">

											<span class="cancelState"
												@click="$noMultipleClicks(cancelOrder)">取消订单</span>
										</div>
										<div class="stateList"
											v-if="userInfo.status == 0 && userInfo.userMessageId == user">

											<span class="state1" @click="$noMultipleClicks(payOrder)">立即支付</span>
											<span class="cancelState"
												@click="$noMultipleClicks(cancelOrder)">取消订单</span>
										</div>



										<div class="stateList" v-if="userInfo.status == 4">

											<span class="state2" @click="$noMultipleClicks(claims)"
												v-if="userInfo.isInsure == 1">申请理赔</span>
											<span class="state1" @click="setConfirm('查看账号','account')"
												v-if="(userInfo.gameCommodityCategory == 5 || userInfo.gameCommodityCategory== 6 ) && userInfo.userMessageId == user">查看账号</span>

										</div>
										<div class="stateList" v-if="userInfo.status == 5">
										</div>
									</div>
									<div v-if="userInfo.status == 0 && userInfo.deliveryType == 3"></div>
									<div v-else class="takeInfo" style="background-color: rgb(25, 144, 254,.2);">
										<div class="takeTitle">订单信息</div>

										<div class="infoItem">
											<span>发货方式或类型：</span>
											<span v-if="userInfo.deliveryType == '1'" style="color: #1D223C;">担保</span>
											<span v-if="userInfo.deliveryType == '2'" style="color: #1D223C;">寄售</span>
											<span v-if="userInfo.deliveryType == '3'" style="color: #1D223C;">收货</span>
											<span v-if="userInfo.deliveryType == '4'" style="color: #1D223C;">代练</span>
											<span v-if="userInfo.deliveryType == '5'" style="color: #1D223C;">热卖</span>
										</div>

										<div class="infoItem" v-if="userInfo.remake != null">
											<span>交易备注：</span>
											<span style="color: #1D223C;">{{userInfo.remake}}</span>
										</div>
										<div class="infoItem"
											v-if="userInfo.gameCommodityCategory == '1' || userInfo.gameCommodityCategory == '12'">
											<span>出货数量：</span>
											<span v-if="userInfo.deliveryType == 3 || userInfo.deliveryType == 5 "
												style="color: #1D223C;">1件={{userInfo.num }}{{userInfo.commodityUnit}}</span>
											<span v-else
												style="color: #1D223C;">{{userInfo.num}}件={{ userInfo.num * userInfo.piecesNum }}{{userInfo.commodityUnit}}</span>
										</div>
										<div class="infoItem" v-else>
											<div
												v-if="userInfo.gameCommodityCategory != 2 && userInfo.gameCommodityCategory != 8">
												<span>出货数量：</span>
												<span style="color: #1D223C;">{{userInfo.num}}</span>
											</div>
										</div>
										<!-- && (userInfo.orderType == 3 || userInfo.orderType == 5) -->
										<div class="infoItem" v-if="userInfo.receiveRole != null ">
											<span>角色名：</span>
											<span style="color: #1D223C;">{{userInfo.receiveRole}}</span>
										</div>
										<!-- v-if="userInfo.sellerId == user" -->
										<div>
											<div class="infoItem" v-for="(item,index) in userInfo.orderInfos">
												<span>{{item.title}}：</span>
												<span style="color: #1D223C;">{{item.content}}</span>
											</div>
										</div>
										<!-- v-else -->
										<div>
											<div class="infoItem"
												v-for="(item,index) in userInfo.gameCommodityFieldValueList">
												<span>{{item.name}}：</span>
												<span style="color: #1D223C;">{{item.value}}</span>
											</div>
										</div>

										<div v-if="userInfo.sellerId != user && userInfo.status == 1 ">
											<div class="validatRole"
												v-if="userInfo.receiveRole == null && (userInfo.orderType == 3 || userInfo.orderType == 5)">
												<div class="fl role" style="margin-top: 20px;">
													<span>收货角色：</span>
													<el-select no-data-text="暂无历史角色，请键入您的角色名" :filter-method="$event=>dataFilter($event)" filterable
														allow-create  :reserve-keyword="true" placeholder="请选择角色"
														ref="searchSelect" style="width: 225px;" :popper-append-to-body="false"
														v-model="roleValue" >
														<el-option v-for="(items,indexs) in roleList" 
															:label="items.receiveRole" :value="items.receiveRole">
														</el-option>
													</el-select>
												</div>
												
												<div class="fl role" style="margin-top: 20px;">
													<span>确认角色：</span>
													<el-select no-data-text="暂无历史角色，请键入您的角色名" placeholder="请选择角色" :filter-method="$event=>dataFilter1($event)" filterable
														allow-create  :reserve-keyword="true"
														ref="searchSelect" style="width: 225px;" :popper-append-to-body="false"
														v-model="configRoleValue" >
														<el-option v-for="(items,indexs) in roleList" :key="items.receiveRole"
															:label="items.receiveRole" :value="items.receiveRole">
														</el-option>
													</el-select>
													<el-button style="margin-left: 20px;"
														@click="$noMultipleClicks(configRole)"
														type="primary">确认</el-button>
												</div>
											</div>
										</div>

										<div
											v-if="userInfo.status == 1 && userInfo.sellerId == user && userInfo.gameCommodityCategory == 8 ">
											<div class="validatRole" v-if="userInfo.receiveRole == null ">
												<div class="fl role" style="margin-top: 20px;">
													<span>代练角色：</span>
													<el-select no-data-text="暂无历史角色，请键入您的角色名" :filter-method="$event=>dataFilter($event)" filterable
														allow-create  :reserve-keyword="true" placeholder="请选择角色"
														ref="searchSelect" style="width: 225px;" :popper-append-to-body="false"
														v-model="roleValue" >
														<el-option v-for="(items,indexs) in roleList" 
															:label="items.receiveRole" :value="items.receiveRole">
														</el-option>
													</el-select>
												</div>
												<div class="fl role" style="margin-top: 20px;">
													<span>确认角色：</span>
													<el-select no-data-text="暂无历史角色，请键入您的角色名" placeholder="请选择角色" :filter-method="$event=>dataFilter1($event)" filterable
														allow-create  :reserve-keyword="true"
														ref="searchSelect" style="width: 225px;" :popper-append-to-body="false"
														v-model="configRoleValue" >
														<el-option v-for="(items,indexs) in roleList" 
															:label="items.receiveRole" :value="items.receiveRole">
														</el-option>
													</el-select>
													<el-button style="margin-left: 20px;"
														@click="$noMultipleClicks(configRole)"
														type="primary">确认</el-button>
												</div>
											</div>
										</div>


										<div
											v-if="userInfo.status == 1 && userInfo.sellerId == user && userInfo.gameCommodityCategory == 2 ">
											<div class="validatRole" v-if="userInfo.receiveRole == null ">
												<div class="fl role" style="margin-top: 20px;">
													<span>代练角色：</span>
													<el-select no-data-text="暂无历史角色，请键入您的角色名" :filter-method="$event=>dataFilter($event)" filterable
														allow-create  :reserve-keyword="true" placeholder="请选择角色"
														ref="searchSelect" style="width: 225px;" :popper-append-to-body="false"
														v-model="roleValue" >
														<el-option v-for="(items,indexs) in roleList" 
															:label="items.receiveRole" :value="items.receiveRole">
														</el-option>
													</el-select>
												</div>
												<div class="fl role" style="margin-top: 20px;">
													<span>确认角色：</span>
													<el-select no-data-text="暂无历史角色，请键入您的角色名" placeholder="请选择角色" :filter-method="$event=>dataFilter1($event)" filterable
														allow-create  :reserve-keyword="true"
														ref="searchSelect" style="width: 225px;" :popper-append-to-body="false"
														v-model="configRoleValue" >
														<el-option v-for="(items,indexs) in roleList" 
															:label="items.receiveRole" :value="items.receiveRole">
														</el-option>
													</el-select>
													<el-button style="margin-left: 20px;"
														@click="$noMultipleClicks(configRole)"
														type="primary">确认</el-button>
												</div>
											</div>
										</div>

									</div>

									<div class="takeInfo" style="background-color: rgb(25, 144, 254,.2);"
										v-if="userInfo.gameCommodityAccountInfos != null && userInfo.gameCommodityAccountInfos.length != 0 && userInfo.gameCommodityAccountInfos != undefined ">
										<div class="takeTitle">账号信息</div>

										<div class="infoItem"
											v-for="(item,index) in userInfo.gameCommodityAccountInfos">
											<span>{{item.title}}：</span>
											<span style="color: #1D223C;">{{item.content}}</span>
										</div>
									</div>

									<div class="takeInfo">
										<div class="takeTitle1">订单信息</div>

										<div class="infoItem">
											<span style="color: #1D223C;">订单编号：{{userInfo.id}}</span>
											<span  v-clipboard:copy="userInfo.id"
												v-clipboard:success="onCopy"
												style="margin-left: 8px;color: #0085FF;cursor:pointer;">复制</span>
										</div>
										<div class="infoItem" v-if="userInfo.orderType == 3">
											<span style="color: #1D223C;">交易备注：{{userInfo.title}}</span>
										</div>
										<div>
											<el-table :data="[userInfo]" style="width: 100%">
												<el-table-column prop="date" label="商品信息">
													<template slot-scope="scope">
														<div @click="openLine(scope.row)">
															<div class="infoTitle1 el1">
																<span>{{scope.row.gameCommodityId_dictText}}</span>
															</div>
															<div class="idItems">
																<span>{{scope.row.gameName}}</span>
																<span
																	v-if="scope.row.districtName !='' && scope.row.districtName !=null">｜{{scope.row.districtName}}</span>
															</div>
															<div class="idItems">
																<span>{{scope.row.gameCommodityCategory_dictText}}</span>
															</div>
														</div>
													</template>
												</el-table-column>
												<el-table-column prop="name"
													:label="(userInfo.gameCommodityCategory == '8' || userInfo.gameCommodityCategory == '2') ? '佣金':'单价'"
													align="right" width="80">
													<!-- <template slot="header" >
														<div>{{scope.row.gameCommodityCategory == '8' ? '佣金':'单价'}}</div>
													</template> -->
													<template slot-scope="scope">
														<div class="idItems">
															<span
																style="color: #1D223C;font-weight: bold;">¥{{scope.row.price}}</span>
														</div>
													</template>
												</el-table-column>
												<el-table-column prop="address" label="数量" align="right" width="80">
													<template slot-scope="scope">
														<div class="idItems">
															<span
																style="color: #1D223C;font-weight: bold;">{{scope.row.num}}</span>
														</div>
													</template>
												</el-table-column>
												<el-table-column prop="address"
													v-if="userInfo.gameCommodityCategory == '8' || userInfo.gameCommodityCategory == '2'"
													label="保证金" align="right" width="80">
													<template slot-scope="scope">
														<div class="idItems">
															<span
																style="color: #FF5C00;font-weight: bold;">{{scope.row.practicalPrice}}</span>
														</div>
													</template>
												</el-table-column>

												<el-table-column prop="address" v-else label="金额" align="right"
													width="80">
													<template slot-scope="scope">
														<div class="idItems">
															<span
																style="color: #FF5C00;font-weight: bold;">{{scope.row.practicalPrice}}</span>
														</div>
													</template>
												</el-table-column>
											</el-table>

											<div v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'"
												class="idItems" style="text-align: right;margin-top: 5px;">
												<span style="color: #A5A7B1;font-weight: 500;">安全险：</span>
												<span
													style="color: #1D223C;font-weight: 500;margin-right: 20px;">¥{{userInfo.insurePrice}}</span>
												<span style="color: #A5A7B1;font-weight: 500;">订单金额：</span>
												<span
													style="color: #FF5C00;font-weight: 500;">¥{{userInfo.practicalPrice}}</span>
											</div>
										</div>
									</div>
									<div class="takeInfo" style="border-bottom: none;">
										<div class="takeTitle">交易信息</div>
										<div style="margin-top: 10px;">
											<el-timeline>
												<el-timeline-item v-for="(activity, index) in activities" 
													:timestamp="activity.createTime" :color="activity.color">
													{{activity.message}}
												</el-timeline-item>
											</el-timeline>
										</div>
									</div>
								</div>
							</div>

							<div style="display: flex;align-items: center;margin: 0 auto;"
								v-if="Object.keys(userInfo).length ==0 && leftAction != 6 && Object.keys(singleChatInfo).length ==0 ">
								<div style="width: 200px;">
									<img style="filter: brightness(0.1);width: 200px;"
										:src="require('@/static/image/index/logo.png')" />
									<div style="width: 200px;letter-spacing: 8px;font-size: 14px;margin-top: 10px;">
										让交易成为一种享受</div>
								</div>
							</div>

							<div class="" v-if="leftAction == 6" style="padding: 30px;">
								<div class="fl search">
									<div class="fl">
										<div class="searchText">保单编号：</div>
										<el-input v-prevent-space style="width: 300px;" v-model="applySearch.id"
											placeholder="请输入保单编号"></el-input>
									</div>
									<div class="fl">
										<div class="searchText">游戏名称：</div>
										<el-select style="width: 300px;" clearable v-model="applySearch.gameName"
											placeholder="请选择">
											<el-option v-for="item in gameList" :key="item.name" :label="item.name"
												:value="item.name">
											</el-option>
										</el-select>
										<!-- <el-input style="width: 300px;" placeholder="请输入保单编号"></el-input> -->
									</div>
									<div style="margin-left: 11px;">
										<el-button type="primary" @click="applySearch.pageNo = 1;getApplyList()"
											icon="el-icon-search">搜索</el-button>
									</div>
								</div>

								<div class="roleTitles">
									<el-badge value="" class="titleItem">
										<div @click="setType('')" :class="{'action':applySearch.status === ''}">全部订单
										</div>
									</el-badge>
									<el-badge value="" class="titleItem">
										<div @click="setType(0)" :class="{'action':applySearch.status === 0}">待审核</div>
									</el-badge>
									<el-badge value="" class="titleItem">
										<div @click="setType(1)" :class="{'action':applySearch.status === 1}">已通过</div>
									</el-badge>
									<el-badge value="" class="titleItem">
										<div @click="setType(2)" :class="{'action':applySearch.status === 2}">已拒绝</div>
									</el-badge>

								</div>

								<div class="tableList">
									<el-table ref="multipleTable" :data="applyList" tooltip-effect="dark"
										style="width: 100%">
										<el-table-column type="index" :index="indexMethod" width="80">
											<template slot="header">
												<div class="cardPrice">序号</div>
											</template>
										</el-table-column>
										<el-table-column width="240" align="center">
											<template slot="header">
												<div class="cardPrice">订单号</div>
											</template>
											<template slot-scope="scope">
												<div>
													{{scope.row.orderId}}
												</div>
											</template>
										</el-table-column>
										<el-table-column prop="name" width="200" align="center">
											<template slot="header">
												<div class="cardPrice">成交时间</div>
											</template>
											<template slot-scope="scope">
												<div>{{scope.row.successTime}}</div>
											</template>
										</el-table-column>

										<el-table-column width="150" align="center">
											<template slot="header">
												<div class="cardPrice">游戏名称</div>
											</template>
											<template slot-scope="scope">
												<div>
													<span>{{scope.row.gameName}}</span>
												</div>
											</template>
										</el-table-column>
										<el-table-column width="130" align="center">
											<template slot="header">
												<div class="cardPrice">状态</div>
											</template>
											<template slot-scope="scope">
												<div v-if="scope.row.status == 0" style="color: #E6A23C;">
													待审核
												</div>
												<div v-if="scope.row.status == 1" style="color: #67C23A;">
													已通过
												</div>
												<div v-if="scope.row.status == 2" style="color: red">
													已拒绝
												</div>
											</template>
										</el-table-column>
										<el-table-column align="center" width="180">
											<template slot="header">
												<div class="cardPrice">保额/元</div>
											</template>
											<template slot-scope="scope">
												<div>{{scope.row.sumAmount}}</div>
											</template>
										</el-table-column>
										<el-table-column align="center" width="180">
											<template slot="header">
												<div class="cardPrice">保费/元</div>
											</template>
											<template slot-scope="scope">
												<div>{{scope.row.sumPremium}}</div>
											</template>
										</el-table-column>
										<el-table-column width="240" align="center">
											<template slot="header">
												<div class="cardPrice">保单号</div>
											</template>
											<template slot-scope="scope">
												<div>{{scope.row.id}}</div>
											</template>
										</el-table-column>
										<el-table-column width="240" align="center">
											<template slot="header">
												<div class="cardPrice">起保时间</div>
											</template>
											<template slot-scope="scope">
												<div>{{scope.row.insureStartTime}}</div>
											</template>
										</el-table-column>
										<el-table-column width="240" align="center">
											<template slot="header">
												<div class="cardPrice">终保时间</div>
											</template>
											<template slot-scope="scope">
												<div>{{scope.row.insureEndTime}}</div>
											</template>
										</el-table-column>

										<el-table-column width="180" align="center">
											<template slot="header">
												<div class="cardPrice">收款姓名</div>
											</template>
											<template slot-scope="scope">
												<div>{{scope.row.name}}</div>
											</template>
										</el-table-column>
										<el-table-column width="180" align="center">
											<template slot="header">
												<div class="cardPrice">开户行</div>
											</template>
											<template slot-scope="scope">
												<div>{{scope.row.bank}}</div>
											</template>
										</el-table-column>
										<el-table-column width="200" align="center">
											<template slot="header">
												<div class="cardPrice">银行账号</div>
											</template>
											<template slot-scope="scope">
												<div>{{scope.row.bankNo}}</div>
											</template>
										</el-table-column>
										<el-table-column width="200" align="center">
											<template slot="header">
												<div class="cardPrice">收款个人身份证号</div>
											</template>
											<template slot-scope="scope">
												<div>{{scope.row.cardNo}}</div>
											</template>
										</el-table-column>
										<el-table-column width="100" align="left">
											<template slot="header">
												<div class="cardPrice">身份证照片</div>
											</template>
											<template slot-scope="scope">
												<div style="color: #0085FF;" @click="isShowPics = true">查看</div>
												<el-image-viewer v-if="isShowPics" :on-close="closeImage"
													:url-list="[scope.row.frontPic,scope.row.backPic]" />
											</template>
										</el-table-column>

										<el-table-column width="100" align="left">
											<template slot="header">
												<div class="cardPrice">事故照片</div>
											</template>
											<template slot-scope="scope">
												<div v-if="scope.row.pic != null && scope.row.pic != ''"
													@click="isShowPics1 = true" style="color: #0085FF;">查看</div>

												<el-image-viewer v-if="isShowPics1" :on-close="closeImage"
													:url-list="scope.row.pic.split(',')" />
											</template>
										</el-table-column>
										<el-table-column width="100" align="left">
											<template slot="header">
												<div class="cardPrice">事故视频</div>
											</template>
											<template slot-scope="scope">
												<div v-if="scope.row.video != null && scope.row.video != ''"
													@click="isShowPics2 = true;showVideo = scope.row.video.split(',')"
													style="color: #0085FF;">查看</div>

												<!-- <el-image-viewer v-if="isShowPics2" :on-close="closeImage"
													:url-list="scope.row.video.split(',')" /> -->



											</template>
										</el-table-column>
										<el-table-column width="240" fixed="right" align="center">
											<template slot="header">
												<div class="cardPrice">操作</div>
											</template>
											<template slot-scope="scope">
												<div>
													<!-- <el-link v-if="scope.row.status ==1" style="margin-left: 15px;"
														type="primary">下载发票</el-link> -->
													<el-link @click="$noMultipleClicks(downPoliy,scope.row)"
														v-if="scope.row.status ==1 || scope.row.status ==0"
														style="margin-left: 15px;" type="primary">下载保单</el-link>
													<!-- <el-link @click="supplement(scope.row)" v-if="scope.row.status ==0"
														style="margin-left: 15px;" type="primary">补充</el-link>
													<el-link @click="adoptSet(scope.row)" v-if="scope.row.status ==0"
														style="margin-left: 15px;" type="primary">通过</el-link>
													<el-link v-if="scope.row.status ==0"
														@click="insuranceSet(scope.row)" style="margin-left: 15px;"
														type="primary">拒绝</el-link> -->

													<el-link @click="message('请等待审核','success')"
														v-if="scope.row.status ==0" style="margin-left: 15px;"
														type="primary">待审核</el-link>
													<el-link v-if="scope.row.status ==2"
														@click="insuranceSet(scope.row)" style="margin-left: 15px;"
														type="primary">查看原因</el-link>
												</div>
											</template>
										</el-table-column>

									</el-table>
								</div>

								<div class="fl"
									style="justify-content: space-between;font-size: 18px;margin-top: 20px;">
									<div>
									</div>
									<el-pagination @size-change="applySize" @current-change="applyCurrent"
										:current-page="applySearch.pageNo" :page-sizes="[10, 20,30,40]"
										:page-size="applySearch.pageSize"
										layout="total, sizes, prev, pager, next, jumper" :total="applyTotal">
									</el-pagination>
								</div>
							</div>
						</div>
					</el-col>

				</el-row>
			</div>
		</div>
		<!-- 取消订单弹框 -->
		<el-dialog title="取消订单" style="margin-top: 200px;" :visible.sync="cancelVisible" width="700px">
			<div>
				<!-- v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'" -->
				<div class="fl" v-if="userInfo.gameCommodityCategory == '2'">
					<div class="dialogLable must">哪方原因</div>
					<el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'"
						v-model="causeType" @input="causeInput" :label="1">买家原因</el-radio>
					<el-radio v-else v-model="causeType" @input="causeInput" :label="1">代练原因</el-radio>
					<el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'"
						v-model="causeType" @input="causeInput" :label="2">卖家原因</el-radio>
					<el-radio v-else v-model="causeType" @input="causeInput" :label="2">玩家原因</el-radio>
				</div>
				<div class="fl" v-else-if=" userInfo.gameCommodityCategory == '8'">
					<div class="dialogLable must">哪方原因</div>
					<el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'"
						v-model="causeType" @input="causeInput" :label="1">买家原因</el-radio>
					<el-radio v-else v-model="causeType" @input="causeInput" :label="1">代练原因</el-radio>
					<el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'"
						v-model="causeType" @input="causeInput" :label="2">卖家原因</el-radio>
					<el-radio v-else v-model="causeType" @input="causeInput" :label="2">玩家原因</el-radio>
				</div>
				<div class="fl"
					v-else-if=" userInfo.gameCommodityCategory != '2' && userInfo.gameCommodityCategory != '8'">
					<div class="dialogLable must">哪方原因</div>
					<el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'"
						v-model="causeType" @input="causeInput" :label="1">买家原因</el-radio>
					<el-radio v-else v-model="causeType" @input="causeInput" :label="1">代练原因</el-radio>
					<el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'"
						v-model="causeType" @input="causeInput" :label="2">卖家原因</el-radio>
					<el-radio v-else v-model="causeType" @input="causeInput" :label="2">玩家原因</el-radio>
				</div>
				<div class="fl" style="margin-top: 20px;">
					<div class="dialogLable must">取消原因</div>
					<div class="dialogValue">
						<el-select v-model="causeValue" style="width: 250px;" :popper-append-to-body="false"
							placeholder="请选择取消原因">
							<el-option v-for="item in causeList" :key="item.reason" :label="item.reason"
								:value="item.reason">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelVisible = false">取 消</el-button>
				<el-button style="background-color: #FF5C00;color: #fff;" @click="$noMultipleClicks(cancelState)">确
					定</el-button>
			</span>
		</el-dialog>

		<!-- 申请客服介入弹框 -->
		<el-dialog title="申请客服介入" :visible.sync="applyforShow" width="700px" style="margin-top: 200px;">
			<div>
				<div class="fl formItem">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">申请原因：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space v-model="applyforValue" placeholder="请输入"></el-input>
					</div>
				</div>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="applyforShow = false">取 消</el-button>
				<el-button type="primary" @click="applyfor">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 确认收货弹框 -->
		<el-dialog :title="confirmText" :visible.sync="confirmShow" width="700px" style="margin-top: 200px;">
			<div>
				<div class="fl formItem">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">验证方式：</span>
					</div>
					<div class="submitValue">
						<el-select :popper-append-to-body="false" v-model="reasonValue" placeholder="请选择">
							<el-option v-for="item in reason" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="fl formItem" v-if="reasonValue == 1">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">支付密码：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space maxlength="6" show-password v-model="payPass"
							placeholder="请输入您的支付密码"></el-input>
					</div>
				</div>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="confirmShow = false">取 消</el-button>
				<el-button type="primary" @click="$noMultipleClicks(configStart)">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 申请理赔弹框 -->
		<el-dialog title="上传证据" :visible.sync="applyShow" width="700px">
			<div>
				<el-form label-width="120px" class="demo-ruleForm">
					<el-form-item label="理赔银行账户:" prop="name">
						<el-select style="width: 376px;" v-model="applyInsureInfo.accountId" placeholder="请选择理赔账户">
							<el-option v-for="(item,index) in accountList" :key="item.id"
								:label="item.bank+'(' +idCardEncryption(item.card)+')'" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="买家手机:">
						<el-input v-prevent-space v-model="applyInsureInfo.phone" placeholder="请输入您的手机号码"
							style="width: 376px;"></el-input>
					</el-form-item>
					<el-form-item label="买家QQ:">
						<el-input v-prevent-space v-model="applyInsureInfo.qq" placeholder="请输入您的QQ号"
							style="width: 376px;"></el-input>
					</el-form-item>
					<el-form-item label="申报理由">
						<div class="reason">
							<el-input v-prevent-space v-model="applyInsureInfo.applyReason" type="textarea"
								placeholder="请输入您的申报理由" style="width: 276px;background-color: #fff;"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="截图证据:" prop="resource">
						<div class="item4">
							<div class="uploadTip">上传截图可留作纠纷验证证据，强烈建议上传截图。</div>
							<div>
								<el-upload accept=".jpg,.jpeg,.png"
									action="https://api.cc007.com/jeecg-boot/sys/common/upload" list-type="picture-card"
									:on-success="handlePictureCardPreview" :on-remove="handleRemove"
									:on-error="handleError" ref="uploadFile">
									<i class="el-icon-plus"></i>
								</el-upload>
							</div>
							<div class="item5">请上传jpg、png格式文件，文件大小不超过5M</div>
						</div>
					</el-form-item>
					<el-form-item label="视频证据:" prop="resource">
						<div class="item4">
							<div class="uploadTip">上传视频可留作纠纷验证证据，强烈建议上传视频。</div>
							<div>
								<el-upload accept=".mp4" action="https://api.cc007.com/jeecg-boot/sys/common/upload"
									:on-success="handlePictureCardPreview1" :on-remove="handleRemove1"
									:on-error="handleError" ref="uploadFile" :file-list="fileList">
									<el-button size="small" type="primary">点击上传</el-button>
								</el-upload>
							</div>
							<div class="item5">请上传Mp4等格式文件，文件大小不超过50M。</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="applyShow = false">取 消</el-button>
				<el-button style="background-color: #FF5C00;color: #fff;" @click="$noMultipleClicks(applyClaims)">确
					定</el-button>
			</span>
		</el-dialog>




		<input type="file" id="image" @change="onFileChange" accept="image/*" style="display: none;" />
		<input type="file" id="video" @change="onVideoChange" accept="video/*" style="display: none;" />
		<div v-if="music" style="z-index: -1;">
			<audio ref="audioMp" :src="require('@/static/tips.mp3')" autoplay loop></audio>
		</div>

		<div class="popUp" v-if="isShowPics2">
			<div style="width:1080px;height:100%;margin: 0 auto;">
				<video-player ref="videoPlayer" :playsinline="false" :options="playOptions(showVideoIndex)" />
			</div>
			<img @click="leftsClick" class="popLeft" :src="require('@/static/image/chat/bg_left.png')" />
			<img @click="rightsClick" class="popRight" :src="require('@/static/image/chat/bg_right.png')" />
			<img @click="isShowPics2 = false" class="popClose" :src="require('@/static/image/chat/bg_close.png')" />
		</div>


		<el-dialog :visible.sync="dialogTableVisible" title="发送图片" width="500px">
			<div style="display: flex;justify-content: center;">
				<el-image class="img_box" style="width: 200px;" :src="imgInfo.tempFilePath">
				</el-image>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogTableVisible = false">取消</el-button>
					<el-button type="primary" @click="$noMultipleClicks(sendTheImg)"> 发送 </el-button>
				</span>
			</template>
		</el-dialog>


	</div>
</template>
<script>
	import {
		timeList,
		voiceCall,
		updateStatus,
		cancelReasonList,
		getOrderList,
		getSaleOrderList,
		cancelOrder,
		cancelOrderSale,
		queryDetail,
		conform,
		withdrawList,
		applyInsure,
		orderInsureList,
		gameList,
		sessionRecordList,
		tradeMessageList,
		orderFieldListByOrderId,
		saveReceiveRole,
		applyService,
		queryOne,
		ePolicy,
		updateLastReplyTime,
		queryDetailAll,
		queryPic,
		queryPicByType,
		getConfig,
		fush,
		updateLastReplyTimeById,
		closeConsultById,
		receiveRoleList
	} from '@/api/index.js'
	import websdk from 'easemob-websdk'
	import {
		conn
	} from '@/assets/utils/WebIM/initWeb.js'
	// 引入截图插件
	import ScreenShort from "js-web-screen-shot";
	// 引入表情资源
	import emoji from '@/config/emoji';

	import {
		Loading
	} from 'element-ui';

	import noticeBar from '@/components/noticeBar.vue'
	import headBar from '@/components/HeadBar.vue';
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
	import {
		getToken
	} from '@/utils/auth.js'
	import axios from 'axios'
	//格式化时间
	// import {formatDate} from '@/assets/utils/format.js';
	export default {
		components: {
			noticeBar,
			headBar,
			ElImageViewer
		},
		data() {
			return {
				isShowPics: false,
				isShowPics1: false,
				isShowPics2: false,
				showVideo: [],
				showVideoIndex: 0,
				isHandleDrag: false,

				// 申请客服介入原因
				applyforShow: false,
				applyforValue: '',

				roleValue: '',
				configRoleValue: '',

				// 代练 
				formList: [],
				// configRoleValue:'',
				// roleValue:'',
				recordList: [],
				// 回收代练信息
				singleChatInfo: '',
				gameList: [],
				// 理赔账户
				accountList: [],
				accountValue: '',
				applyShow: false,
				applyPic: [],
				applyVideo: [],
				fileList: [],
				applyTotal: 0,
				applySearch: {
					gameName: '',
					id: '',
					pageNo: 1,
					pageSize: 10,
					status: ''
				},
				applyList: [],
				applyInsureInfo: {
					// 订单id
					orderId: '',
					accountId: '',
					// 买家qq
					qq: '',
					// 买家手机
					phone: '',
					// 买家申保理由	
					applyReason: '',
					// 图片
					pic: '',
					// 视频
					video: '',

				},
				// 确认收货弹框
				confirmShow: false,
				confirmType: '',
				confirmText: '',
				reasonValue: 1,
				reason: [{
					value: 1,
					label: '支付密码验证'
				}],
				payPass: '',
				music: false,
				// 和那个角色在聊天
				roleIm: 'user',
				// 取消订单弹框
				cancelVisible: false,
				emojiShow: false,
				// 表情资源
				emojiList: emoji.obj,
				// 提示音
				isWarning: true,
				leftAction: '0',
				leftList: [{
						text: '最近会话',
						image: require('@/static/image/chat/chat1.png'),
						actionImage: require('@/static/image/chat/chat8.png'),
						value: '1'
					},
					{
						text: '历史订单',
						image: require('@/static/image/chat/chat2.png'),
						actionImage: require('@/static/image/chat/chat9.png'),
						value: '2'
					},
					{
						text: '买家订单',
						image: require('@/static/image/chat/chat3.png'),
						actionImage: require('@/static/image/chat/chat10.png'),
						value: '14'
					},
					{
						text: '卖家订单',
						image: require('@/static/image/chat/chat4.png'),
						actionImage: require('@/static/image/chat/chat11.png'),
						value: '15'
					},
					{
						text: '代练订单',
						image: require('@/static/image/chat/chat5.png'),
						actionImage: require('@/static/image/chat/chat12.png'),
						value: '4'

					},
					{
						text: '售后咨询',
						image: require('@/static/image/chat/chat6.png'),
						actionImage: require('@/static/image/chat/chat13.png'),
						value: '3'
					},

					{
						text: '保单列表',
						image: require('@/static/image/chat/chat15.png'),
						actionImage: require('@/static/image/chat/chat16.png'),
						value: '7'
					},
					{
						text: '商家聊天',
						image: require('@/static/image/chat/chat6.png'),
						actionImage: require('@/static/image/chat/chat13.png'),
						value: '8'
					},
				],
				tableData: [],
				noticeData: ['买卖双方不得主动提供或向对方询问联系方式，如有发现作封停账户处理', '买卖双方不得主动提供或向对方询问联系方式，如有发现作封停账户处理',
					'买卖双方不得主动提供或向对方询问联系方式，如有发现作封停账户处理'
				],
				chatValue: '',
				user: '', //自己应用下的用户id
				pwd: '111111', //用户密码
				inputMessage: '',
				messageList: [], //消息
				activities: [],

				leftType: 1,
				search: {
					id: '',
					pageNo: 1,
					pageSize: 10,
				},
				isLoading: true,
				userInfo: {},
				userInfoIndex: '',
				serveInfo: {},
				causeType: 1,
				causeValue: '',
				causeList: [],
				intervalId: null,
				intervalId1: null,
				totalSeconds: '',
				totalSeconds1: '',
				// 快捷键code
				keyCodeList: [],
				isSearchFocus: false,
				checkSubmitFlg: false,
				imgInfo: {},
				dialogTableVisible: false,
				bannerList: [],
				payPassword: '',
				//* 在data中创建一个noClick默认启动
				noClick: true,
				getAcale: 1,
				roleList:[]

			}
		},
		computed: {
			minutes() {
				return Math.floor(this.totalSeconds / 60);
			},
			seconds() {
				return this.totalSeconds % 60;
			},

			minutes1() {
				return Math.floor(this.totalSeconds1 / 60);
			},
			seconds1() {
				return this.totalSeconds1 % 60;
			},
		},
		mounted() {
			let that = this
			window.addEventListener('keydown', this.handleKeyDown);
			window.addEventListener('keyup', this.handleKeyUp);
			document.addEventListener("visibilitychange", function() {
				if (that.$route.name == 'chat') {
					if (document.visibilityState == "hidden") {
						console.log('隐藏');
					} else if (document.visibilityState == "visible") {
						if (conn.logOut) {
							that.userInfo = {}
							that.getOrderList()
							that.loginIM().then(result => {

							})
						}
					}
				}
			});

			let devicewidth = window.screen.availWidth //获取当前分辨率下的可是区域宽度
			if (devicewidth < 2200) {
				let scale = devicewidth / 2200 // 分母——设计稿的尺寸
				this.getAcale = scale //放大缩小相应倍数
			}
		},
		destroyed() {
			window.removeEventListener('keydown', this.handleKeyDown);
			window.removeEventListener('keyup', this.handleKeyUp);
			//取消键盘监听事件
			window.document.onkeydown = null
			// conn.close();
		},

		created() {
			this.user = this.$store.state.userInfo.id
			localStorage.setItem('chatPromptTone', true)
			// let _that = this
			// _that.bodyScale()
			this.isWarning = localStorage.getItem('chatPromptTone') 
			console.log(this.isWarning)
			// 监听全局点击事件
			document.addEventListener('click', (e) => {
				if (this.$refs.dialogBox && !this.$refs.dialogBox.contains(e.target)) {
					this.emojiShow = false
				}
			});
			if (conn.logOut) {
				if (this.$route.params.id.indexOf('youxi') != -1) {
					this.leftAction = 7
					this.getSessionRecordList()
					queryOne({
						userId: this.$route.params.id,
						type: localStorage.getItem('recordType')
					}).then(res => {
						if (res.code == 200) {
							this.singleChat(res.result)
						}
					})

				} else if (this.$route.params.id != 0) {
					this.queryDetail(this.$route.params.id)

				}
				this.getMessage();
			} else {
				this.loginIM()
			}
			this.getOrderList()
			queryPicByType({
				type: 9
			}).then(res => {
				this.bannerList = res.result
			})

			fush().then(res => {
				this.payPassword = res.result.payPassword
			})

		},

		methods: {
			toLink1(item) {
				if (!this.isEmpty(item.url)) {
					window.open(item.url)
				}

			},
			sendTheImg() {
				this.dialogTableVisible = false
				let that = this
				const file = {
					data: null, // file 对象。
					filename: '', //文件名称。
					filetype: '' //文件类型。
				}
				const url = window.URL || window.webkitURL
				const img = new Image() //手动创建一个Image对象

				let chatType = '';
				let targetId = '';

				if (Object.keys(this.singleChatInfo).length != 0) {
					chatType = 'singleChat'
					if (this.singleChatInfo.userId == this.user) {
						targetId = this.singleChatInfo.consultId
					} else {
						targetId = this.singleChatInfo.userId
					}
				} else {
					if (this.roleIm == 'user') {
						chatType = 'groupChat'
						targetId = this.userInfo.chatroomId
					} else {
						chatType = 'singleChat'
						targetId = this.serveInfo.id
					}
				}


				let option = {
					type: "img",
					file: file,
					to: targetId,
					chatType: chatType,
					onFileUploadError: function() {
						console.log("onFileUploadError");
					},
					onFileUploadProgress: function(e) {
						console.log(e);
					},
					// 图片文件上传成功。
					onFileUploadComplete: function() {},
					thumbnailHeight: 200,
					thumbnailWidth: 200,
				};
				const imgFile = this.imgInfo.imgFile
				file.data = imgFile
				file.filename = imgFile.name
				file.filetype = imgFile.type
				console.log(imgFile)
				img.src = url.createObjectURL(imgFile) //创建Image的对象的url
				img.onload = async () => {

					try {
						// 创建一条图片消息。
						let msg = WebIM.message.create(option);
						if (conn.logOut) {
							this.loginIM().then(result => {
								setTimeout(res => {
									conn
										.send(msg)
										.then((res) => {
											that.sendMessageSuccessful(res.message, targetId,
												'img');
										})
										.catch((e) => {

										});
								}, 1000)
							})
						} else {
							conn
								.send(msg)
								.then((res) => {
									that.sendMessageSuccessful(res.message, targetId, 'img');
								})
								.catch((e) => {
									// 图片文件发送失败。
									console.log("Fail");
								});
						}

					} catch (error) {

						if (error.type && error?.data) {
							handleSDKErrorNotifi(error.type, error.data.error || 'none')
						} else {
							handleSDKErrorNotifi(0, 'none')
						}
					}
				}
			},
			pasteImg(event) {
				let that = this
				const items = (event.clipboardData || event.originalEvent.clipboardData).items;
				for (let i = 0; i < items.length; i++) {
					if (items[i].type.indexOf("image") !== -1) {
						const blob = items[i].getAsFile();
						const reader = new FileReader();
						reader.onload = (e) => {
							that.imgInfo = {
								imgFile: blob,
								tempFilePath: e.target.result
							}
							that.dialogTableVisible = true
						};

						reader.readAsDataURL(blob);
					} else if (items[i].type.indexOf("text") !== -1) {
						return false
					}
				}
			},
			setConfirm(text, type) {
				if (type == 'completedOrder') {
					// 判断是否设置支付密码
					if (this.payPassword != undefined && this.payPassword != null && this.payPassword != '') {
						this.confirmText = text
						this.confirmType = type
						this.confirmShow = true
					} else {

						this.message('请先设置支付密码', 'error')
						setTimeout(() => {
							this.$router.push('/mineIndex/30')
						}, 1500)
					}
				} else {
					this.confirmText = text
					this.confirmType = type
					this.confirmShow = true
				}

			},
			handleEnd(e) {
				e.preventDefault();
				this.isHandleDrag = false
			},
			handleDrop(e) {
				let that = this
				e.preventDefault();
				let files = e.dataTransfer.files;

				this.isHandleDrag = false
				if (files.length == 0) return

				const file = files[0];
				const fileReader = new FileReader()
				fileReader.onload = (e) => {
					console.log(e.target.result)

					let chatType = '';
					let targetId = '';

					if (Object.keys(this.singleChatInfo).length != 0) {
						chatType = 'singleChat'
						if (this.singleChatInfo.userId == this.user) {
							targetId = this.singleChatInfo.consultId
						} else {
							targetId = this.singleChatInfo.userId
						}
					} else {
						if (this.roleIm == 'user') {
							chatType = 'groupChat'
							targetId = this.userInfo.chatroomId
						} else {
							chatType = 'singleChat'
							targetId = this.serveInfo.id
						}
					}
					let option = {
						// 消息类型。
						type: 'video',
						file: {
							size: file.size,
							name: file.name,
							type: file.type,
							blob: e.target.result
						},
						to: targetId,
						// 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
						chatType: chatType,

						// 图片文件上传成功。
						onFileUploadComplete: function() {
							console.log("onFileUploadComplete");
						},
						thumbnailHeight: 200,
						thumbnailWidth: 200,
					};
					// 创建一条图片消息。
					let msg = WebIM.message.create(option);
					// 调用 `send` 方法发送该图片消息。
					conn
						.send(msg)
						.then((res) => {
							// that.messageList.push(res.message);
							// setTimeout(() => {
							// 	that.handleScroll()
							// }, 200)
							console.log(res.message)
							// 图片文件成功发送。
							that.sendMessageSuccessful(res.message, targetId, type);
						})
						.catch((e) => {
							// 图片文件发送失败。
							console.log("Fail");
						});



				}
				fileReader.readAsArrayBuffer(file);
				// 读取为 DataURL  

				// let file = WebIM.utils.getFileUrl(reader.readAsDataURL(blob));

				// console.log(file)
				// if (files[0].type.indexOf('video') == -1 && files[0].type.indexOf('image') == -1) {
				// 	this.message('请上传图片或视频格式', 'error')
				// 	return
				// }

				// var file = files[0]
				// let param = new FormData() // 创建form对象
				// param.append('file', files[0])
				// let config = {
				// 	headers: {
				// 		'Content-Type': 'multipart/form-data',
				// 		'X-Access-Token': getToken()
				// 	}
				// } // 添加请求头
				// axios.post('http://api.cc007.com/jeecg-boot/sys/common/upload', param, config)
				// 	.then(response => {
				// 		// 上传成功
				// 		if (response.data.code == 200) {
				// 			// response.data.result
				// 			let chatType = '';
				// 			let targetId = '';
				// 			let type='';
				// 			if (Object.keys(this.singleChatInfo).length != 0) {
				// 				chatType = 'singleChat'
				// 				if (this.singleChatInfo.userId == this.user) {
				// 					targetId = this.singleChatInfo.consultId
				// 				} else {
				// 					targetId = this.singleChatInfo.userId
				// 				}
				// 			} else {
				// 				if (this.roleIm == 'user') {
				// 					chatType = 'groupChat'
				// 					targetId = this.userInfo.chatroomId
				// 				} else {
				// 					chatType = 'singleChat'
				// 					targetId = this.serveInfo.id
				// 				}
				// 			}

				// 			if (files[0].type.indexOf('image') != -1){
				// 				type = 'img'
				// 			}else{
				// 				type = 'video'
				// 			}

				// 			console.log(response.data.result)
				// 			let option = {
				// 				// 消息类型。
				// 				type:type,
				// 				url: response.data.result,
				// 				to: targetId,
				// 				// 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
				// 				chatType: chatType,

				// 				// 图片文件上传成功。
				// 				onFileUploadComplete: function() {
				// 					console.log("onFileUploadComplete");
				// 				},
				// 				thumbnailHeight: 200,
				// 				thumbnailWidth: 200,
				// 			};
				// 			// 创建一条图片消息。
				// 			let msg = WebIM.message.create(option);
				// 			// 调用 `send` 方法发送该图片消息。
				// 			conn
				// 				.send(msg)
				// 				.then((res) => {
				// 					// that.messageList.push(res.message);
				// 					// setTimeout(() => {
				// 					// 	that.handleScroll()
				// 					// }, 200)
				// 					console.log(res.message)
				// 					// 图片文件成功发送。
				// 					that.sendMessageSuccessful(res.message, targetId, type);
				// 				})
				// 				.catch((e) => {
				// 					// 图片文件发送失败。
				// 					console.log("Fail");
				// 				});
				// 		} else {
				// 			this.message('发送失败', 'error')
				// 		}
				// 	})







			},
			handleDragOver(e) {
				e.preventDefault();
				this.isHandleDrag = true
			},
			searchFocus() {
				this.isSearchFocus = true
				console.log('获得焦点')
			},
			searchBlur() {
				this.isSearchFocus = false
				console.log('失去焦点')
			},
			// 监听键盘事件
			handleKeyDown(e) {
				// 快捷键思路 列如alt+z 触发事件 按下是将alt的code码push到一个数组内 在次触发按下事件时假如监听的code时z就触发方法
				// 已按下shift建
				if (e?.keyCode == 16) {
					this.keyCodeList?.push(e?.keyCode)
				}
				// 是否已经按下了shift键
				const isShift = this.keyCodeList?.includes(16)
				// 触发工单 shift+回车
				const isWorkOrder = isShift && e?.keyCode == 13

				if (isWorkOrder) {
					console.log('按下shift+回车键')
					// this.chatValue += '\n'
					this.keyCodeList = []
					return
				}
				if (e?.keyCode == 13) {
					if (Object.keys(this.singleChatInfo).length != 0 && this.leftAction == 7) {
						this.sendSingleMessage()
					} else {
						if (this.isSearchFocus == true) {
							this.getOrderList()
						} else {
							this.sendMessage()
						}

					}


				}
			},
			handleKeyUp() {
				this.keyCodeList = []
			},
			// 催促发货
			startCountdown() {
				this.intervalId = setInterval(() => {
					if (this.totalSeconds > 0) {
						this.totalSeconds--;
					} else {
						this.stopCountdown();
					}
				}, 1000);
			},
			startCountdown1() {
				this.intervalId1 = setInterval(() => {
					if (this.totalSeconds1 > 0) {
						this.totalSeconds1--;
					} else {
						this.stopCountdown();
					}
				}, 1000);
			},
			stopCountdown() {
				clearInterval(this.intervalId);
				clearInterval(this.intervalId1);
			},
			// updateLastReplyTimes() {
			// 	updateLastReplyTime().then()
			// },
			// 切换取消原因
			causeInput(e) {
				this.causeType = e
				this.causeValue = ''
				this.getCancelReasonList()
			},
			// 获取取消原因
			getCancelReasonList() {
				cancelReasonList({
					reasonType: this.causeType
				}).then(res => {
					if (res.code == 200) {
						this.causeList = res.result
					}
				})
			},
			// 选择订单
			selectOrder(item, index) {
				this.isLoading = true
				this.roleIm = 'user'
				this.queryDetail(item.id)
				this.userInfoIndex = index
				// this.userInfo = item
				this.messageList = []
				this.serveInfo = {}
				this.stopCountdown()
				if (item.chatroomId != null) {
					this.getHistoryMessages(item.chatroomId, 'groupChat')

				}
				this.getTradeMessageList(item.id, 'groupChat')
			},
			getTradeMessageList(id) {
				tradeMessageList({
					id: id
				}).then(res => {
					if (res.code == 200) {
						this.activities = res.result
					}
				})
			},
			getOrderList() {
				timeList(Object.assign(this.search, {
					type: this.leftType
				})).then(res => {
					if (res.code == 200) {
						if (this.search.pageNo == 1) {
							this.tableData = res.result.records
						} else {
							this.tableData = [...this.tableData, ...res.result.records]
						}
					}


				})
			},
			leftClick(index) {
				this.leftAction = index
				this.messageList = []
				this.serveInfo = {}
				this.tableData = []
				this.userInfo = {}
				this.userInfoIndex = ''
				this.singleChatInfo = {}
				this.search.pageNo = 1
				// 处理左侧点击菜单
				if (this.leftList[this.leftAction].value == 1 || this.leftList[this.leftAction].value == 2 || this
					.leftList[this.leftAction].value == 3 || this.leftList[this.leftAction].value == 4) {
					this.leftType = this.leftList[this.leftAction].value
					this.getOrderList()
				} else if (this.leftList[this.leftAction].value == 14) {
					this.getBuyerOrder()
				} else if (this.leftList[this.leftAction].value == 15) {
					this.getSaleOrderList()
				} else if (this.leftList[this.leftAction].value == 7) {
					this.getApplyList()
					this.getGameList()
				} else if (this.leftList[this.leftAction].value == 8) {
					this.getSessionRecordList()

				}

			},
			// 获取单聊列表
			getSessionRecordList() {
				let search = {
					pageNo: this.search.pageNo,
					pageSize: this.search.pageSize,
				}
				sessionRecordList(search).then(res => {
					if (res.code == 200) {
						if (search.pageNo == 1) {
							this.recordList = res.result.records
						} else {
							this.recordList = [...this.recordList, ...res.result.records]
						}
					}
				})
			},
			// 获取游戏列表
			getGameList() {
				gameList({
					pageNo: 1,
					pageSize: 1000
				}).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
					}
				})
			},
			// 理赔列表
			getApplyList() {
				orderInsureList(this.applySearch).then(res => {
					if (res.code == 200) {
						this.applyList = res.result.records
						this.applyTotal = res.result.total
					}
				})
			},
			applySize(e) {
				this.applySearch.pageSize = e
				this.getApplyList()
			},
			applyCurrent(e) {
				this.applySearch.pageNo = e
				this.getApplyList()
			},
			// 买家订单数据
			getBuyerOrder() {
				let info = {
					isTrain: 0,
					orderType: '',
					type: 1
				}
				getOrderList(Object.assign(this.search, info)).then(res => {
					if (res.code == 200) {
						if (this.search.pageNo == 1) {
							this.tableData = res.result.records
						} else {
							this.tableData = [...this.tableData, ...res.result.records]
						}
					}
				})
			},
			// 卖家订单数据
			getSaleOrderList() {
				let info = {
					isTrain: 0,
					orderType: '',
					type: 1
				}
				getSaleOrderList(Object.assign(this.search, info)).then(res => {
					if (res.code == 200) {
						if (this.search.pageNo == 1) {
							this.tableData = res.result.records
						} else {
							this.tableData = [...this.tableData, ...res.result.records]
						}
					}
				})
			},
			//登陆
			loginIM() {
				let that = this
				return new Promise((resolve, reject) => {
					conn.close();
					var options = {
						user: that.user,
						pwd: 123456,
						appKey: WebIM.config.appkey,
						success: function(res) {
							console.log(that.$route.params)
							if (that.$route.params.id.indexOf('youxi') != -1) {
								that.leftAction = 7
								that.getSessionRecordList()
								queryOne({
									userId: that.$route.params.id,
									type: localStorage.getItem('recordType')
								}).then(res => {
									if (res.code == 200) {
										that.singleChat(res.result)
									}
								})

							} else if (that.$route.params.id != 0 && that.$route.params.id != '0') {
								that.queryDetail(that.$route.params.id)
							}


						},
						error: function(err) {
							console.log('登录失败l ')
							// console.log(err)
						}
					};
					conn.open(options).then(() => {

						that.getMessage();
						resolve();
					}).catch((reason) => {

						// conn.close();
						// that.loginIM() 
						console.log("login fail", reason);
					});


				});

			},
			base64ToFile(base64, mime, filename) {
				let arr = base64.split(',')
				let type = mime || arr[0].match(/:(.*?);/)[1]
				let suffix = mine.split('/')[1]
				let fileName = filename || `未命名.${suffix}`
				let bstr = atob(arr[1])
				let n = bstr.length
				let u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				return new File([u8arr], fileName, {
					type
				})
			},

			//发送消息
			sendMessage() {
				if (conn.logOut) {
					this.loginIM().then(result => {
						if (this.chatValue.split(/[\s\n]/).join('') == '') {
							return;
						}
						let that = this;
						let contentMsg = that.chatValue;
						let toID = ''
						let type = ''
						if (this.roleIm == 'user') {
							toID = this.userInfo.chatroomId;
							type = 'groupChat'
						} else if (this.roleIm == 'service') {
							toID = this.serveInfo.id;
							type = 'singleChat'
						}
						//收信息用户id
						let id = conn.getUniqueId(); // 生成本地消息id
						let msg = new WebIM.message('txt', id); // 创建文本消息
						msg.set({
							msg: contentMsg, // 消息内容
							to: toID, // 接收消息对象（用户id）
							chatType: type, // 设置为单聊	
							ext: {
								type: type,
								chatroomId: toID
							},
							success: function(id, serverMsgId) {
								that.chatValue = '';

								that.sendMessageSuccessful(contentMsg, toID, 'txt');
							},
							error: function(e) {}
						});
						setTimeout(res => {
							conn.send(msg.body);
							that.chatValue = '';
						}, 1000)

					})
					console.log('执行了')


				} else {
					if (this.chatValue.split(/[\s\n]/).join('') == '') {
						return;
					}
					let that = this;
					let contentMsg = that.chatValue;
					let toID = ''
					let type = ''
					if (this.roleIm == 'user') {
						toID = this.userInfo.chatroomId;
						type = 'groupChat'
					} else if (this.roleIm == 'service') {
						toID = this.serveInfo.id;
						type = 'singleChat'
					}
					//收信息用户id
					let id = conn.getUniqueId(); // 生成本地消息id
					let msg = new WebIM.message('txt', id); // 创建文本消息
					msg.set({
						msg: contentMsg, // 消息内容
						to: toID, // 接收消息对象（用户id）
						chatType: type, // 设置为单聊	
						ext: {
							type: type,
							chatroomId: toID
						},
						success: function(id, serverMsgId) {
							that.chatValue = '';

							that.sendMessageSuccessful(contentMsg, toID, 'txt');
						},
						error: function(e) {}
					});
					conn.send(msg.body);
					that.chatValue = '';
				}
				// return




			},
			// 单聊处理发送信息
			sendSingleMessage() {
				if (conn.logOut) {
					this.loginIM().then(result => {
						if (this.chatValue.split(/[\s\n]/).join('') == '') {
							// this.message('发送内容不能为空!', 'info');
							return;
						}
						let that = this;
						let contentMsg = that.chatValue;

						let toID = this.user == this.singleChatInfo.userId ? this.singleChatInfo.consultId : this
							.singleChatInfo
							.userId
						let type = 'singleChat'


						//收信息用户id
						let id = conn.getUniqueId(); // 生成本地消息id
						let msg = new WebIM.message('txt', id); // 创建文本消息
						msg.set({
							msg: contentMsg, // 消息内容
							to: toID, // 接收消息对象（用户id）
							chatType: type, // 设置为单聊	
							ext: {
								type: type,
								chatroomId: toID
							},
							success: function(id, serverMsgId) {
								console.log('成功发送消息');
								that.sendMessageSuccessful(contentMsg, toID, 'txt');
							},
							fail: function(e) {
								console.log("发送消息失败");
							}
						});
						setTimeout(res => {
							conn.send(msg.body).then(() => {
								that.chatValue = '';
							}).catch((e) => {
								that.message('信息发送失败，请重新登录', 'error')
							});
						}, 1000)

					})
				} else {
					if (this.chatValue.split(/[\s\n]/).join('') == '') {
						// this.message('发送内容不能为空!', 'info');
						return;
					}
					let that = this;
					let contentMsg = that.chatValue;

					let toID = this.user == this.singleChatInfo.userId ? this.singleChatInfo.consultId : this
						.singleChatInfo
						.userId
					let type = 'singleChat'


					//收信息用户id
					let id = conn.getUniqueId(); // 生成本地消息id
					let msg = new WebIM.message('txt', id); // 创建文本消息
					msg.set({
						msg: contentMsg, // 消息内容
						to: toID, // 接收消息对象（用户id）
						chatType: type, // 设置为单聊	
						ext: {
							type: type,
							chatroomId: toID
						},
						success: function(id, serverMsgId) {
							console.log('成功发送消息');
							that.sendMessageSuccessful(contentMsg, toID, 'txt');
						},
						fail: function(e) {
							console.log("发送消息失败");
						}
					});
					conn.send(msg.body).then(() => {
						that.chatValue = '';
					}).catch((e) => {
						console.log(e)
						that.message('信息发送失败，请重新登录', 'error')
					});
				}


			},

			// 选择图片
			selectImage() {
				document.getElementById("image").click()
			},
			// 选择视频
			selectVideo() {
				document.getElementById("video").click()
			},
			//  上传图片
			onFileChange(e) {
				this.sendPrivateImg()
			},
			// 上传视频
			onVideoChange(e) {
				this.sendPrivateVideo()
			},
			sendPrivateVideo() {
				let that = this
				if (conn.logOut) {
					this.loginIM().then(result => {
						// 选择本地视频文件。
						let input = document.getElementById("video");
						let file = WebIM.utils.getFileUrl(input);
						let allowType = {
							mp4: true,
							wmv: true,
							avi: true,
							rmvb: true,
							mkv: true,
						};
						if (file.filetype.toLowerCase() in allowType) {

							let chatType = '';
							let targetId = '';

							if (Object.keys(this.singleChatInfo).length != 0) {
								chatType = 'singleChat'
								if (this.singleChatInfo.userId == this.user) {
									targetId = this.singleChatInfo.consultId
								} else {
									targetId = this.singleChatInfo.userId
								}
							} else {
								if (this.roleIm == 'user') {
									chatType = 'groupChat'
									targetId = this.userInfo.chatroomId
								} else {
									chatType = 'singleChat'
									targetId = this.serveInfo.id
								}
							}
							let option = {
								// 消息类型。
								type: "video",
								file: file,
								// 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
								to: targetId,
								// 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
								chatType: chatType,
								onFileUploadError: function() {
									// 视频文件上传失败。
									console.log("onFileUploadError");
								},
								onFileUploadProgress: function(e) {
									// 视频文件上传进度。
									console.log(e);
								},
								onFileUploadComplete: function() {
									// 视频文件上传成功。
									console.log("onFileUploadComplete");
								},
								ext: {},
							};
							// 创建一条视频消息。
							let msg = WebIM.message.create(option);
							// 调用 `send` 方法发送该视频消息。
							setTimeout(res => {
								conn
									.send(msg)
									.then((res) => {

										that.sendMessageSuccessful(res.message, targetId, 'video');
									})
									.catch((e) => {
										console.log("Fail");
									});
							}, 1000)

						}
					})
				} else {
					// 选择本地视频文件。
					let input = document.getElementById("video");
					let file = WebIM.utils.getFileUrl(input);
					let allowType = {
						mp4: true,
						wmv: true,
						avi: true,
						rmvb: true,
						mkv: true,
					};
					if (file.filetype.toLowerCase() in allowType) {

						let chatType = '';
						let targetId = '';

						if (Object.keys(this.singleChatInfo).length != 0) {
							chatType = 'singleChat'
							if (this.singleChatInfo.userId == this.user) {
								targetId = this.singleChatInfo.consultId
							} else {
								targetId = this.singleChatInfo.userId
							}
						} else {
							if (this.roleIm == 'user') {
								chatType = 'groupChat'
								targetId = this.userInfo.chatroomId
							} else {
								chatType = 'singleChat'
								targetId = this.serveInfo.id
							}
						}
						let option = {
							// 消息类型。
							type: "video",
							file: file,
							// 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
							to: targetId,
							// 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
							chatType: chatType,
							onFileUploadError: function() {
								// 视频文件上传失败。
								console.log("onFileUploadError");
							},
							onFileUploadProgress: function(e) {
								// 视频文件上传进度。
								console.log(e);
							},
							onFileUploadComplete: function() {
								// 视频文件上传成功。
								console.log("onFileUploadComplete");
							},
							ext: {},
						};
						// 创建一条视频消息。
						let msg = WebIM.message.create(option);
						// 调用 `send` 方法发送该视频消息。
						conn
							.send(msg)
							.then((res) => {
								// that.messageList.push(res.message);
								// setTimeout(() => {
								// 	that.handleScroll()
								// }, 200)
								console.log(res)
								that.sendMessageSuccessful(res.message, targetId, 'video');
							})
							.catch((e) => {
								// 视频文件发送失败。例如，本地用户被禁言或封禁。
								console.log("Fail");
							});
					}
				}

			},
			sendPrivateImg() {
				let that = this;
				// 选择本地图片文件。
				if (conn.logOut) {
					this.loginIM().then(result => {
						let input = document.getElementById("image");

						let file = WebIM.utils.getFileUrl(input);
						let allowType = {
							jpg: true,
							gif: true,
							png: true,
							bmp: true,
						};
						console.log(file)
						if (file.filetype.toLowerCase() in allowType) {
							let chatType = '';
							let targetId = '';

							if (Object.keys(this.singleChatInfo).length != 0) {
								chatType = 'singleChat'
								if (this.singleChatInfo.userId == this.user) {
									targetId = this.singleChatInfo.consultId
								} else {
									targetId = this.singleChatInfo.userId
								}
							} else {
								if (this.roleIm == 'user') {
									chatType = 'groupChat'
									targetId = this.userInfo.chatroomId
								} else {
									chatType = 'singleChat'
									targetId = this.serveInfo.id
								}
							}


							let option = {
								// 消息类型。
								type: "img",
								file: file,
								ext: {
									// 图片文件长度，单位为字节。
									file_length: file.data.size,
								},
								// 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
								to: targetId,
								// 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
								chatType: chatType,
								// 图片文件上传失败。
								onFileUploadError: function() {
									console.log("onFileUploadError");
								},
								// 图片文件上传进度。
								onFileUploadProgress: function(e) {
									console.log(e);
								},
								// 图片文件上传成功。
								onFileUploadComplete: function() {
									console.log("onFileUploadComplete");
								},
								thumbnailHeight: 200,
								thumbnailWidth: 200,
							};
							// 创建一条图片消息。
							let msg = WebIM.message.create(option);
							// 调用 `send` 方法发送该图片消息。
							setTimeout(res => {
								conn
									.send(msg)
									.then((res) => {
										that.sendMessageSuccessful(res.message, targetId, 'img');
									})
									.catch((e) => {
										console.log("Fail");
									});
							}, 1000)

						}
					})
				} else {
					let input = document.getElementById("image");

					let file = WebIM.utils.getFileUrl(input);
					let allowType = {
						jpg: true,
						gif: true,
						png: true,
						bmp: true,
					};
					console.log(file)
					if (file.filetype.toLowerCase() in allowType) {
						let chatType = '';
						let targetId = '';

						if (Object.keys(this.singleChatInfo).length != 0) {
							chatType = 'singleChat'
							if (this.singleChatInfo.userId == this.user) {
								targetId = this.singleChatInfo.consultId
							} else {
								targetId = this.singleChatInfo.userId
							}
						} else {
							if (this.roleIm == 'user') {
								chatType = 'groupChat'
								targetId = this.userInfo.chatroomId
							} else {
								chatType = 'singleChat'
								targetId = this.serveInfo.id
							}
						}


						let option = {
							// 消息类型。
							type: "img",
							file: file,
							ext: {
								// 图片文件长度，单位为字节。
								file_length: file.data.size,
							},
							// 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
							to: targetId,
							// 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
							chatType: chatType,
							// 图片文件上传失败。
							onFileUploadError: function() {
								console.log("onFileUploadError");
							},
							// 图片文件上传进度。
							onFileUploadProgress: function(e) {
								console.log(e);
							},
							// 图片文件上传成功。
							onFileUploadComplete: function() {
								console.log("onFileUploadComplete");
							},
							thumbnailHeight: 200,
							thumbnailWidth: 200,
						};
						// 创建一条图片消息。
						let msg = WebIM.message.create(option);
						// 调用 `send` 方法发送该图片消息。
						conn
							.send(msg)
							.then((res) => {
								// that.messageList.push(res.message);
								// setTimeout(() => {
								// 	that.handleScroll()
								// }, 200)
								console.log(res.message)
								// 图片文件成功发送。
								that.sendMessageSuccessful(res.message, targetId, 'img');
							})
							.catch((e) => {
								// 图片文件发送失败。
								console.log("Fail");
							});
					}
				}

			},


			//成功发送消息，进行消息加入到聊天信息数组中
			sendMessageSuccessful(data, toID, type) {
				console.log(data)
				let userMsg = {};
				userMsg.to = toID;
				userMsg.from = this.user;
				userMsg.msg = data;
				if (!this.isEmpty(data.url)) {
					userMsg.url = data.url
				}


				userMsg.time = new Date().getTime();
				userMsg.msgType = type;
				userMsg.type = type;
				//存储信息
				console.log(userMsg)
				this.messageList.push(userMsg);

				// 进行本地存储
				localStorage.setItem(toID, JSON.stringify({
					msgList: this.messageList,
					unRead: ''
				}));
				setTimeout(() => {
					this.handleScroll()
				}, 200)
			},
			// 集成收到文本信息方法
			getMessage() {
				let that = this;
				conn.listen({
					onOpened: function(message) {
						console.log('用户已上线') // 连接成功  
					},
					onError: function(error) {
						console.log('进入error')
						console.log(error)
						// that.loginIM() 
					},

					onTextMessage: function(message) {
						if (message.from == that.$store.state.userInfo.id) {
							return
						}
						that.music = true
						setTimeout(() => {
							that.music = false
						}, 1000)

						// 普通文本信息
						let value = {
							type: 'txt',
							id: message.id,
							msg: message.data,
							to: message.to,
							from: message.from,
							time: Number(message.time),
							chatType: message.type
						}
						if (!that.isEmpty(message.ext.profile)) {
							value.profile = message.ext.profile
						}


						if (value.msg.indexOf('发货完成') != -1 || value.msg.indexOf('交易成功') != -1 || value
							.msg
							.indexOf('成功') != -1 || value.msg.indexOf('交易取消') != -1 || value.msg
							.indexOf(
								'申请理赔') != -1 || value.msg.indexOf('完成售后') != -1 || value.msg.indexOf(
								'支付订单成功') != -1 || value.msg.indexOf('处理订单') != -1 || value.msg
							.indexOf(
								'申请售后') !=
							-1 || value.msg.indexOf('等待买家付款') != -1 || value.msg.indexOf('接单成功') != -
							1 || value
							.msg.indexOf('接单成功') != -1 || value.msg.indexOf('申请取消') != -1 || value.msg
							.indexOf(
								'开始代练') != -1 || value.msg.indexOf('代练完成') != -1 || value.msg.indexOf(
								'已支付代练费用') != -1) {

							setTimeout(() => {
								that.search.pageNo = 1
								if (that.leftList[that.leftAction].value == 1 || that.leftList[
										that
										.leftAction]
									.value == 2 || that
									.leftList[that.leftAction].value == 3) {
									that.leftType = that.leftList[that.leftAction].value
									that.getOrderList()
								} else if (that.leftList[that.leftAction].value == 4) {
									that.getBuyerOrder()
								} else if (that.leftList[that.leftAction].value == 5) {
									that.getSaleOrderList()
								}

							}, 1500)
						}
						if (that.userInfo.chatroomId == message.to) {
							if (value.msg.indexOf('发货完成') != -1 || value.msg.indexOf('交易成功') != -1 ||
								value.msg
								.indexOf('支付成功') != -1 || value.msg.indexOf('交易取消') != -1 || value.msg
								.indexOf(
									'申请理赔') != -1 || value.msg.indexOf('完成售后') != -1 || value.msg
								.indexOf(
									'收货角色') != -1 || value.msg.indexOf('处理订单') != -1 || value.msg
								.indexOf(
									'申请售后') != -1 || value.msg.indexOf('等待买家付款') != -1 || value.msg
								.indexOf(
									'接单成功') != -1 || value.msg.indexOf('申请取消') != -1 || value.msg
								.indexOf(
									'开始代练') != -1 || value.msg.indexOf('代练完成') != -1 || value.msg
								.indexOf(
									'已支付代练费用') != -1 || value.msg.indexOf(
									'代练角色名') != -1 || value.msg.indexOf(
									'申请客服') != -1) {
								setTimeout(() => {
									that.queryDetail(that.userInfo.id)
									that.getTradeMessageList(that.userInfo.id)

								}, 1500)


							}

							that.messageList.push(value) // 添加到聊天信息数组中
							if (localStorage.getItem(message.to) == null) {
								localStorage.setItem(message.to, JSON.stringify({
									msgList: that.messageList,
									unRead: ''
								}));
							} else {
								let oldDate = JSON.parse(localStorage.getItem(message.to))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = ''
								localStorage.setItem(message.to, JSON.stringify(oldDate));
							}
							setTimeout(() => {
								that.handleScroll()
							}, 300)

							return
						}

						if (message.type == 'groupchat' || message.type == 'groupChat') {
							if (that.roleIm == 'user' && message.to == that.userInfo.chatroomId) {
								that.messageList.push(value) // 添加到聊天信息数组中
								if (localStorage.getItem(message.to) == null) {
									localStorage.setItem(message.to, JSON.stringify({
										msgList: that.messageList,
										unRead: ''
									}));
								} else {
									let oldDate = JSON.parse(localStorage.getItem(message.to))
									oldDate.msgList = [...oldDate.msgList, ...[value]]
									oldDate.unRead = ''
									localStorage.setItem(message.to, JSON.stringify(oldDate));
								}
								if (that.userInfo.chatroomId == message.ext.chatroomId) {
									setTimeout(() => {
										that.handleScroll()
									}, 300)
								}

								return
							}
							console.log(message.to)
							// 进行本地存储
							if (localStorage.getItem(message.to) == null) {
								console.log('进行本地存储')
								// 进行本地存储
								localStorage.setItem(message.to, JSON.stringify({
									msgList: [value],
									unRead: 1
								}));
							} else {
								let oldDate = JSON.parse(localStorage.getItem(message.to))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = Number(oldDate.unRead) + 1
								localStorage.setItem(message.to, JSON.stringify(oldDate));
							}
						} else {

							if (that.roleIm == 'service' && message.from == that.serveInfo.id) {
								that.messageList.push(value) // 添加到聊天信息数组中 
								let oldDate = JSON.parse(localStorage.getItem(message.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = ''
								localStorage.setItem(message.from, JSON.stringify(oldDate));
								setTimeout(() => {
									that.handleScroll()
								}, 300)
								return
							}

							if (message.from == that.singleChatInfo.userId || message.from == that
								.singleChatInfo.consultId) {
								that.messageList.push(value) // 添加到聊天信息数组中 
								let oldDate = JSON.parse(localStorage.getItem(message.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = ''
								localStorage.setItem(message.from, JSON.stringify(oldDate));
								// setTimeout(() => {
								// 	that.handleScroll()
								// }, 300)
								return
							}

							// 进行本地存储
							if (localStorage.getItem(message.from) == null) {
								console.log('进行本地存储')
								// 进行本地存储
								localStorage.setItem(message.from, JSON.stringify({
									msgList: [value],
									unRead: 1
								}));
							} else {
								let oldDate = JSON.parse(localStorage.getItem(message.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = Number(oldDate.unRead) + 1
								localStorage.setItem(message.from, JSON.stringify(oldDate));
							}
						}


						that.$forceUpdate();

					},
					onPictureMessage: function(message) {
						if (message.from == that.$store.state.userInfo.id) {
							return
						}
						let value = {
							type: 'img',
							id: message.id,
							url: message.url,
							to: message.to,
							from: message.from,
							time: Number(message.time),
							chatType: message.type
						}
						if (!that.isEmpty(message.ext.profile)) {
							value.profile = message.ext.profile
						}

						// that.messageList.push(value) // 添加到聊天信息数组中 
						if (message.type == 'groupchat' || message.type == 'groupChat') {
							if (that.roleIm == 'user' && message.to == that.userInfo.chatroomId) {
								that.messageList.push(value) // 添加到聊天信息数组中
								if (localStorage.getItem(message.to) == null) {
									localStorage.setItem(message.to, JSON.stringify({
										msgList: that.messageList,
										unRead: ''
									}));
								} else {
									let oldDate = JSON.parse(localStorage.getItem(message.to))
									oldDate.msgList = [...oldDate.msgList, ...[value]]
									oldDate.unRead = ''
									localStorage.setItem(message.to, JSON.stringify(oldDate));
								}
								if (that.userInfo.chatroomId == message.ext.chatroomId) {
									setTimeout(() => {
										that.handleScroll()
									}, 300)
								}

								return
							}
							// 进行本地存储
							if (localStorage.getItem(message.to) == null) {
								console.log('进行本地存储')
								// 进行本地存储
								localStorage.setItem(message.to, JSON.stringify({
									msgList: [value],
									unRead: 1
								}));
							} else {
								let oldDate = JSON.parse(localStorage.getItem(message.to))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = Number(oldDate.unRead) + 1
								localStorage.setItem(message.to, JSON.stringify(oldDate));
							}
						} else {

							if (that.roleIm == 'service' && message.from == that.serveInfo.id) {
								that.messageList.push(value) // 添加到聊天信息数组中 
								let oldDate = JSON.parse(localStorage.getItem(message.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = ''
								localStorage.setItem(message.from, JSON.stringify(oldDate));
								// setTimeout(() => {
								// 	that.handleScroll()
								// }, 300)
								return
							}

							if (message.from == that.singleChatInfo.userId || message.from == that
								.singleChatInfo.consultId) {
								that.messageList.push(value) // 添加到聊天信息数组中 
								let oldDate = JSON.parse(localStorage.getItem(message.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = ''
								localStorage.setItem(message.from, JSON.stringify(oldDate));
								setTimeout(() => {
									that.handleScroll()
								}, 300)
								return
							}

							// 进行本地存储
							if (localStorage.getItem(message.from) == null) {
								console.log('进行本地存储')
								// 进行本地存储
								localStorage.setItem(message.from, JSON.stringify({
									msgList: [value],
									unRead: 1
								}));
							} else {
								let oldDate = JSON.parse(localStorage.getItem(message.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = Number(oldDate.unRead) + 1
								localStorage.setItem(message.from, JSON.stringify(oldDate));
							}
						}


						that.$forceUpdate();
					},
					onVideoMessage: function(message) {
						if (message.from == that.$store.state.userInfo.id) {
							return
						}
						console.log('收到视频消息')
						console.log(message)
						let value = {
							type: 'video',
							id: message.id,
							url: message.url,
							to: message.to,
							from: message.from,
							time: Number(message.time),
							chatType: message.type
						}
						if (!that.isEmpty(message.ext.profile)) {
							value.profile = message.ext.profile
						}

						// that.messageList.push(value) // 添加到聊天信息数组中 
						if (message.type == 'groupchat' || message.type == 'groupChat') {
							if (that.roleIm == 'user' && message.to == that.userInfo.chatroomId) {
								that.messageList.push(value) // 添加到聊天信息数组中
								if (localStorage.getItem(message.to) == null) {
									localStorage.setItem(message.to, JSON.stringify({
										msgList: that.messageList,
										unRead: ''
									}));
								} else {
									let oldDate = JSON.parse(localStorage.getItem(message.to))
									oldDate.msgList = [...oldDate.msgList, ...[value]]
									oldDate.unRead = ''
									localStorage.setItem(message.to, JSON.stringify(oldDate));
								}
								if (that.userInfo.chatroomId == message.ext.chatroomId) {
									setTimeout(() => {
										that.handleScroll()
									}, 300)
								}

								return
							}
							// 进行本地存储
							if (localStorage.getItem(message.to) == null) {
								console.log('进行本地存储')
								// 进行本地存储
								localStorage.setItem(message.to, JSON.stringify({
									msgList: [value],
									unRead: 1
								}));
							} else {
								let oldDate = JSON.parse(localStorage.getItem(message.to))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = Number(oldDate.unRead) + 1
								localStorage.setItem(message.to, JSON.stringify(oldDate));
							}
						} else {

							if (that.roleIm == 'service' && message.from == that.serveInfo.id) {
								that.messageList.push(value) // 添加到聊天信息数组中 
								let oldDate = JSON.parse(localStorage.getItem(message.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = ''
								localStorage.setItem(message.from, JSON.stringify(oldDate));
								// setTimeout(() => {
								// 	that.handleScroll()
								// }, 300)
								return
							}

							if (message.from == that.singleChatInfo.userId || message.from == that
								.singleChatInfo.consultId) {
								that.messageList.push(value) // 添加到聊天信息数组中 
								let oldDate = JSON.parse(localStorage.getItem(message.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = ''
								localStorage.setItem(message.from, JSON.stringify(oldDate));
								setTimeout(() => {
									that.handleScroll()
								}, 300)
								return
							}

							// 进行本地存储
							if (localStorage.getItem(message.from) == null) {
								console.log('进行本地存储')
								// 进行本地存储
								localStorage.setItem(message.from, JSON.stringify({
									msgList: [value],
									unRead: 1
								}));
							} else {
								let oldDate = JSON.parse(localStorage.getItem(message.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = Number(oldDate.unRead) + 1
								localStorage.setItem(message.from, JSON.stringify(oldDate));
							}
						}


						that.$forceUpdate();
					}
				});

			},
			// 获取会话列表
			getHistoryMessages(id, type) {
				this.messageList = []
				conn.getHistoryMessages({
					targetId: id, // 单聊为对端用户 ID，群组聊天为群组 ID。
					chatType: type, // 会话类型：单聊、群组聊天和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
					pageSize: 20, // 每次获取的消息数量，取值范围为 [1,50]，默认值为 `20`。
					searchDirection: 'up', // 消息搜索方向。`up` 表示按消息时间戳递减的方向获取，即先获取最新消息；`down` 表示按消息时间戳递增的方向获取，即先获取最老的消息。
				}).then(res => {
					this.messageList = res.messages.reverse()
					setTimeout(() => {
						this.handleScroll()
					}, 800)
				})
				// 如果有缓存取缓存
				if (localStorage.getItem(id) == null) {

				} else {

					let oldDate = JSON.parse(localStorage.getItem(id))
					// console.log('oldDate')
					// console.log(oldDate)
					// this.messageList = oldDate.msgList

					oldDate.unRead = ''
					localStorage.setItem(id, JSON.stringify(oldDate));
					// setTimeout(() => {
					// 	this.handleScroll()
					// }, 800)
				}

			},
			handleScroll() {
				this.$refs.chatCenter.scrollTop = this.$refs.chatCenter.scrollHeight - this.$refs.chatCenter
					.offsetHeight - 10
			},
			// 选择表情
			selectEmoji(item) {
				if (this.chatValue == null) {
					this.chatValue = ''
				}
				this.chatValue += item
				this.$refs.chatValue.focus()
			},
			renderTxt(txt = '') {
				let rnTxt = [];
				let match = null;
				const regex = /(\[.*?\])/g;
				let start = 0;
				let index = 0;
				while ((match = regex.exec(txt))) {
					index = match.index;
					if (index > start) {
						rnTxt.push(txt.substring(start, index));
					}
					if (match[1] in emoji.obj) {
						const v = emoji.obj[match[1]];
						rnTxt.push(this.customEmoji(v));
					} else {
						rnTxt.push(match[1]);
					}
					start = index + match[1].length;
				}
				rnTxt.push(txt.substring(start, txt.length));

				return rnTxt.toString().replace(/,/g, '');
			},
			customEmoji(value) {
				return `<img src="/faces/${value}"  style="width:20px"/>`
			},
			chatCenter() {
				// if (this.isLoading && this.checkBottom(event)) {
					// this.isLoading = false
					let chatType = '';
					let targetId = '';


					if (Object.keys(this.singleChatInfo).length != 0) {
						chatType = 'singleChat'
						if (this.singleChatInfo.userId == this.user) {
							targetId = this.singleChatInfo.consultId
						} else {
							targetId = this.singleChatInfo.userId
						}
					} else {
						if (this.roleIm == 'user') {
							chatType = 'groupChat'
							targetId = this.userInfo.chatroomId
						} else {
							chatType = 'singleChat'
							targetId = this.serveInfo.id
						}
					}
					conn.getHistoryMessages({
						targetId: targetId, // 单聊为对端用户 ID，群组聊天为群组 ID。
						chatType: chatType, // 会话类型：单聊、群组聊天和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
						cursor: this.messageList[0].id,
						pageSize: 10, // 每次获取的消息数量，取值范围为 [1,50]，默认值为 `20`。
						searchDirection: 'up', // 消息搜索方向。`up` 表示按消息时间戳递减的方向获取，即先获取最新消息；`down` 表示按消息时间戳递增的方向获取，即先获取最老的消息。
					}).then(res => {
						if (res.messages.length != 0) {
							if (res.messages[0].to == this.messageList[0].to) {
								let lastScrollHeight = this.$refs.chatCenter.scrollHeight
								this.messageList = [...res.messages.reverse(), ...this.messageList]
								setTimeout(() => {
									let distanceFromBottom = this.$refs.chatCenter.scrollHeight -
										lastScrollHeight
									this.$refs.chatCenter.scrollTop = distanceFromBottom
									this.isLoading = true
								}, 200)
							}

						}else{
							this.message('没有更多数据了~')
						}
					})
				// }

			},
			checkBottom(event) {
				const {
					scrollTop,
					scrollHeight,
					clientHeight
				} = event.target;
				return scrollTop <= 5; // 5px的缓冲区
			},
			// 处理订单信息 立即支付
			payOrder() {
				console.log(this.userInfo)
				let info = {
					id: this.userInfo.payId,
					districtName: this.userInfo.districtName,
					title: this.userInfo.gameCommodityId_dictText,
					price: this.userInfo.totalPrice,
					createTime: this.userInfo.createTime
				}
				// localStorage.setItem('orderPay', JSON.stringify(info));
				this.$router.push({
					path: '/orderPay',
					query: {
						info: JSON.stringify(info)
					}
				})
			},
			// 提醒卖家发货
			remind(type) {
				if (this.totalSeconds == 0 || this.totalSeconds == '') {
					voiceCall({
						id: this.userInfo.id,
						type: type
					}).then(res => {
						if (res.code == 200) {
							this.message('提醒成功', 'success')
							this.totalSeconds = 180
							this.startCountdown()
						}
					})
				}

			},
			// 确认收货
			configStart() {
				if (this.payPass == '') {
					this.message('请输入支付密码', 'error')
				}
				if (this.confirmType == 'completedOrder') {
					conform({
						id: this.userInfo.id,
						password: this.payPass
					}).then(res => {
						if (res.code == 200) {
							if (this.userInfo.gameCommodityCategory == 2 || this.userInfo
								.gameCommodityCategory ==
								8) {
								this.message('代练结束', 'success')
							} else {
								this.message('收货成功', 'success')
							}

							this.queryDetail(this.userInfo.id)
							this.confirmShow = false
							this.payPass = ''
						}
					})
				} else {
					queryDetailAll({
						id: this.userInfo.id,
						password: this.payPass
					}).then(res => {
						if (res.code == 200) {
							this.message('验证成功', 'success')
							this.userInfo = res.result
							this.confirmShow = false
							this.payPass = ''
						}
					})
				}

			},
			//卖家发货
			deliver(type) {
				if (this.userInfo.receiveRole == null && (this.userInfo.orderType == 3 || this.userInfo
						.orderType == 5)) {
					this.message('买家未填写收货角色', 'error')
					return
				}
				this.$confirm('请确保您已发货完成！若未发货请关闭此弹窗，发货完成后再点击发货按钮！')
					.then(_ => {
						updateStatus({
							orderId: this.userInfo.id,
							status: type
						}).then(res => {
							if (res.code == 200) {
								this.message('操作成功', 'success')

							}
						})
					})
					.catch(_ => {});

			},
			deliver1(type) {
				if (this.userInfo.receiveRole == null) {
					this.message('未填写代练角色', 'error')
					return
				}
				if (type == 2) {
					this.$confirm('请确认协商完毕，发生纠纷将以聊天记录和订单内容为辅助证据')
						.then(_ => {
							updateStatus({
								orderId: this.userInfo.id,
								status: type
							}).then(res => {
								if (res.code == 200) {
									this.message('操作成功', 'success')
									// this.userInfo.status = type
									// this.tableData[this.userInfoIndex].status = type
								}
							})
						})
						.catch(_ => {});
				} else if (type == 3) {
					this.$confirm('请确认您已代练完成，并截图或录制完工视频发送聊天窗口/保存本地，发生纠纷将作为佐证')
						.then(_ => {
							updateStatus({
								orderId: this.userInfo.id,
								status: type
							}).then(res => {
								if (res.code == 200) {
									this.message('操作成功', 'success')
									// this.userInfo.status = type
									// this.tableData[this.userInfoIndex].status = type
								}
							})
						})
						.catch(_ => {});
				} else {
					updateStatus({
						orderId: this.userInfo.id,
						status: type
					}).then(res => {
						if (res.code == 200) {
							this.message('操作成功', 'success')
							// this.userInfo.status = type
							// this.tableData[this.userInfoIndex].status = type
						}
					})
				}


			},
			dataFilter(val){
				this.roleValue = val
			},
			dataFilter1(val){
				this.configRoleValue = val
			},
			customerService() {
				console.log(this.userInfo)
				if (this.userInfo.status == 4 || this.userInfo.status == 5 || this.userInfo.status == 6 || this
					.userInfo
					.status == -1) {
					if (this.userInfo.afterSellServiceId == null) {
						this.applyforValue = ''
						this.applyforShow = true
						return
					} else {
						this.message('已申请客服介入', 'success')
						// this.serveInfo = {
						// 	id: this.userInfo.afterSellServiceId
						// }
						// this.roleIm = 'service'
						// this.getHistoryMessages(this.serveInfo.id, 'singleChat')
					}

				} else {
					if (this.userInfo.isService == 0) {
						this.applyforValue = ''
						this.applyforShow = true
					} else {
						// this.serveInfo = {
						// 	id: this.userInfo.serviceId
						// }
						// this.roleIm = 'service'
						// this.getHistoryMessages(this.serveInfo.id, 'singleChat')
						this.message('已申请客服介入', 'success')
					}
				}


			},
			applyfor() {
				let that = this;
				if (this.applyforValue == '') {
					this.message('请输入申请原因', 'error')
					return
				}
				if (this.userInfo.status == 4 || this.userInfo.status == 5 || this.userInfo.status == -1) {
					updateStatus({
						orderId: this.userInfo.id,
						applyReason: this.applyforValue,
						status: 5
					}).then(res => {
						if (res.code == 200) {
							this.applyforShow = false

							// this.userInfo.status = 5
							// this.tableData[this.userInfoIndex].status = 5
							let toID = this.userInfo.chatroomId;
							let type = 'groupChat'

							//收信息用户id
							let id = conn.getUniqueId(); // 生成本地消息id
							let msg = new WebIM.message('txt', id); // 创建文本消息
							let countMsg = '申请客服介入原因:' + this.applyforValue
							msg.set({
								msg: countMsg, // 消息内容
								to: toID, // 接收消息对象（用户id）
								chatType: type, // 设置为单聊	
								ext: {
									type: type,
									chatroomId: toID
								},
								success: function(id, serverMsgId) {
									console.log('成功发送消息');
									that.sendMessageSuccessful(countMsg, toID, 'txt');
								},
								fail: function(e) {
									console.log("发送消息失败");
								}
							});
							conn.send(msg.body);
							this.applyforValue = ''

						}

					})
				} else {
					applyService({
						orderId: this.userInfo.id,
						applyReason: this.applyforValue
					}).then(res => {
						if (res.code == 200) {
							this.applyforShow = false

							// this.userInfo.isService = 1
							let toID = this.userInfo.chatroomId;
							let type = 'groupChat'

							//收信息用户id
							let id = conn.getUniqueId(); // 生成本地消息id
							let msg = new WebIM.message('txt', id); // 创建文本消息
							let countMsg = '申请客服介入原因:' + this.applyforValue
							msg.set({
								msg: countMsg, // 消息内容
								to: toID, // 接收消息对象（用户id）
								chatType: type, // 设置为单聊	
								ext: {
									type: type,
									chatroomId: toID
								},
								success: function(id, serverMsgId) {
									console.log('成功发送消息');
									that.sendMessageSuccessful(countMsg, toID, 'txt');
								},
								fail: function(e) {
									console.log("发送消息失败");
								}
							});
							conn.send(msg.body);
							this.applyforValue = ''

						}

					})
				}

			},
			// customerAfterSale() {
			// 	this.serveInfo = {
			// 		id: this.userInfo.afterSellServiceId
			// 	}
			// 	this.roleIm = 'service'
			// 	this.getHistoryMessages(this.serveInfo.id, 'singleChat')
			// },
			getUnRead(id) {
				if (localStorage.getItem(id) == null) {
					return ''
				} else {
					let oldDate = JSON.parse(localStorage.getItem(id))
					return oldDate.unRead
				}
			},
			getUnReads(id1, id2) {
				let num1 = 0;
				let num2 = 0;
				if (localStorage.getItem(id1) != null) {
					let oldDate = JSON.parse(localStorage.getItem(id1))
					num1 = oldDate.unRead
				}
				if (localStorage.getItem(id2) != null) {
					let oldDate = JSON.parse(localStorage.getItem(id2))
					num2 = oldDate.unRead
				}
				let num3 = num1 + num2;
				if (num3 == 0) {
					return ''
				}
				return num3
			},
			// 关闭聊天框
			closeChat(item, index) {
			
				// if (this.$store.state.serviceRole == 3) {
					closeConsultById({
						id: item.id,
						status: 1
					}).then(res => {
						if (res.code == 200) {
							this.tableData.splice(index, 1)
							if (this.userInfo.id == item.id) {
								this.userInfo = {}
							}
						}
					})
				// } else {
				// 	closeById({
				// 		id: item.id,
				// 		status: 1
				// 	}).then(res => {
				// 		if (res.code == 200) {
				// 			this.tableData.splice(index, 1)
				// 			if (this.userInfo.id == item.id) {
				// 				this.userInfo = {}
				// 			}
				// 		}
				// 	})
				// }
			
			},
			getSingleUnReads(id) {
				let num1 = 0;
				if (localStorage.getItem(id) != null) {
					let oldDate = JSON.parse(localStorage.getItem(id))
					num1 = oldDate.unRead
				}
				if (num1 == 0) {
					return ''
				}
				return num1
			},
			// 设置提示音
			setWarning(value) {
				this.isWarning = value
				localStorage.setItem('chatPromptTone', value)
			},
			onCopy() {
				this.message('复制成功', 'success')
			},
			// 触底分页
			addOrder(event) {
				const {
					scrollTop,
					clientHeight,
					scrollHeight
				} = event.target;
				// 当滚动到底部时，距离顶部+自身高度 >= 总高度
				if (scrollTop + clientHeight > scrollHeight - 50) {
					// 触发刷新操作
					// 处理左侧点击菜单
					this.search.pageNo++
					if (this.leftList[this.leftAction].value == 1 || this.leftList[this.leftAction].value == 2 ||
						this
						.leftList[this.leftAction].value == 3) {
						this.leftType = this.leftList[this.leftAction].value
						this.getOrderList()
					} else if (this.leftList[this.leftAction].value == 4) {
						this.getBuyerOrder()
					} else if (this.leftList[this.leftAction].value == 5) {
						this.getSaleOrderList()
					}
				}
			},
			// 取消订单弹框
			cancelOrder() {
				if (this.userInfo.userMessageId != this.user) {
					this.causeType = 1
					this.causeValue = ''
				} else {
					this.causeType = 1
					this.causeValue = ''
				}
				this.getCancelReasonList()
				this.cancelVisible = true
			},
			// 取消订单
			cancelState() {
				if (this.checkSubmitFlg) {
					return
				}
				this.checkSubmitFlg = true
				setTimeout(() => {
					this.checkSubmitFlg = false
				}, 2500)
				this.cancelVisible = false
				// 卖方
				if (this.userInfo.userMessageId != this.user) {
					cancelOrderSale({
						cancelReason: this.causeValue,
						orderId: this.userInfo.id,
						reasonType: this.causeType
					}).then(res => {
						if (res.code == 200) {
							// this.message('取消成功', 'success')
							this.queryDetail(this.userInfo.id)
						}
					})
				} else {
					// 买方
					cancelOrder({
						cancelReason: this.causeValue,
						orderId: this.userInfo.id,
						reasonType: this.causeType
					}).then(res => {
						if (res.code == 200) {
							// this.message('取消成功', 'success')
							this.queryDetail(this.userInfo.id)
						}
					})
				}
			},
			// 刷新订单
			queryDetail(id) {
				queryDetail({
					id: id
				}).then(res => {
					if (res.code == 200) {
						this.userInfo = res.result
						if (this.userInfoIndex != '') {
							this.tableData[this.userInfoIndex] = this.userInfo
						}
						this.cancelVisible = false
						receiveRoleList({
							districtId: this.userInfo.districtId,
							spannedId: '',
							pageNo: '1',
							pageSize: '1000'
						}).then(res => {
							if (res.code == 200) {
								this.roleList = res.result.records
							}
						})
						

						if (this.userInfo.status == 0) {
							// 下单时间
							let date = new Date(this.userInfo.payOvertime) / 1000;
							// 当前时间
							let date1 = new Date().getTime() / 1000;
							let newData = Number(date.toFixed(0) - (date1.toFixed(0)))
							if (newData > 0) {
								this.totalSeconds = newData
								this.startCountdown()
							} else {
								this.totalSeconds = ''
							}

						} else {
							// 下单时间
							let date = new Date(this.userInfo.remindTime) / 1000;
							// 当前时间
							let date1 = new Date().getTime() / 1000;
							let newData = Number(180 - (date1.toFixed(0) - date.toFixed(0)))
							if (newData > 0) {
								this.totalSeconds = newData
								this.startCountdown()
							} else {
								this.totalSeconds = ''
							}
						}
						let configId = 52
						// 热卖
						if (this.userInfo.isHot == 1) {
							configId = 54
							// 寄售
						} else if (this.userInfo.deliveryType == 2) {
							configId = 53
							// 收货
						} else if (this.userInfo.orderType == 3) {
							configId = 51
							// 代练
						} else if (this.userInfo.gameCommodityCategory == 2 || this.userInfo
							.gameCommodityCategory == 8) {
							configId = 55
							// 账号
						} else if (this.userInfo.gameCommodityCategory == 5 || this.userInfo
							.gameCommodityCategory == 6) {
							configId = 56
						}
						if (this.userInfo.status == 3) {
							getConfig({
								id: configId
							}).then(time => {
								// 下单时间
								let date = new Date(this.userInfo.deliveryTime) / 1000;
								// 当前时间
								let date1 = new Date().getTime() / 1000;
								let newData = 0
								if (this.userInfo.gameCommodityCategory == 2 || this.userInfo
									.gameCommodityCategory == 8) {
									newData = Number(Number(time.result) * 60 * 60 - (date1.toFixed(0) -
										date
										.toFixed(0)))
								} else {
									newData = Number(Number(time.result) * 60 - (date1.toFixed(0) -
										date
										.toFixed(0)))
								}

								if (newData > 0) {
									this.totalSeconds1 = newData
									this.startCountdown1()
								} else {
									this.totalSeconds1 = ''
								}
							})

						}




					}

				})
				updateLastReplyTimeById({
					id: id
				}).then()
			},
			// 申请理赔弹框
			claims() {
				withdrawList({
					pageNo: 1,
					pageSize: 1000,
					type: 1
				}).then(res => {
					if (res.code == 200) {
						this.accountList = res.result.records
						this.applyInsureInfo.orderId = this.userInfo.id
						this.applyPic = []
						this.applyVideo = []
						this.fileList = []
						this.applyShow = true
					}
				})

			},
			applyClaims() {
				this.applyInsureInfo.pic = this.applyPic.join(',')
				this.applyInsureInfo.video = this.applyVideo.join(',')
				applyInsure(this.applyInsureInfo).then(res => {
					if (res.code == 200) {
						this.message('理赔发起成功', 'success')
						this.applyShow = false
						this.queryDetail(this.userInfo.id)
					}

				})
			},
			// 上传失败事件
			handleError(e) {
				this.$message('上传失败', 'error')
			},
			// 上传成功事件
			handlePictureCardPreview(e) {
				this.applyPic.push(e.result)
			},
			// 删除图片
			handleRemove(file, fileList) {
				this.applyPic = []
				fileList.forEach(item => {
					this.applyPic.push(item.response.result)
				})
			},

			// 上传成功事件
			handlePictureCardPreview1(e) {
				let name = e.result.split('/')
				console.log(name[name.length - 1])
				this.applyVideo.push(e.result)
				this.fileList.push({
					name: name[name.length - 1],
					url: e.result
				})
			},
			// 删除图片
			handleRemove1(file, fileList) {
				console.log(file)
				console.log(fileList)
				this.applyVideo = []
				this.fileList = fileList
				fileList.forEach(item => {
					this.applyVideo.push(item.response.result)
				})
			},
			setType(value) {
				this.applySearch.status = value
				this.applySearch.pageNo = 1
				this.getApplyList()
			},
			singleChat(item) {
				console.log(item)
				this.singleChatInfo = item
				if (this.user == item.userId) {
					this.getHistoryMessages(item.consultId, 'singleChat')
				} else {
					this.getHistoryMessages(item.userId, 'singleChat')
				}
			},
			// 验证角色
			configRole() {
				let that = this
				if (this.roleValue == '') {
					this.message('请填写收货角色', 'error')
					return
				}
				if (this.roleValue != this.configRoleValue) {
					this.message('两次填写不一致', 'error')
					return
				}
				saveReceiveRole({
					orderId: this.userInfo.id,
					receiveRole: this.roleValue
				}).then(res => {
					if (res.code == 200) {
						this.message('提交成功', 'success')
						// this.queryDetail(this.userInfo.id)

						// let toID = this.userInfo.chatroomId;
						// let type = 'groupChat'

						// //收信息用户id
						// let id = conn.getUniqueId(); // 生成本地消息id
						// let msg = new WebIM.message('txt', id); // 创建文本消息
						// let countMsg = ''
						// if (this.userInfo.gameCommodityCategory == 8 || this.userInfo
						// 	.gameCommodityCategory == 2) {
						// 	countMsg = '我已填写代练角色名'
						// } else {
						// 	countMsg = '我已填写收货角色'
						// }

						// msg.set({
						// 	msg: countMsg, // 消息内容
						// 	to: toID, // 接收消息对象（用户id）
						// 	chatType: type, // 设置为单聊	
						// 	ext: {
						// 		type: type,
						// 		chatroomId: toID
						// 	},
						// 	success: function(id, serverMsgId) {
						// 		console.log('成功发送消息');
						// 		that.sendMessageSuccessful(countMsg, toID, 'txt');
						// 	},
						// 	fail: function(e) {
						// 		console.log("发送消息失败");
						// 	}
						// });
						// conn.send(msg.body);

					}
				})
			},
			closeImage() {
				this.isShowPics = false
				this.isShowPics1 = false
				this.isShowPics2 = false
			},
			playOptions() {
				let playOptions = {
					// height: "100%",
					// width: "100%",
					playbackRates: [1.0], // 可选的播放速度
					autoplay: true, // 如果为true,浏览器准备好时开始回放
					muted: false, // 默认情况下静音播放
					loop: false, // 是否视频一结束就重新开始
					preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据，auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: "zh-CN",
					aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值，值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小，换句话说，它将按比例缩放以适应其容器
					sources: [{
						type: "video/mp4", // 类型
						src: this.showVideo[this
							.showVideoIndex], // url地址，在使用本地的资源时，需要用require()引入，否则控制台会报错
					}],

					notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息
					controlBar: {
						currentTimeDisplay: true,
						progressControl: true, // 是否显示进度条
						playbackRateMenuButton: true, // 是否显示调整播放倍速按钮
						timeDivider: true, // 当前时间和持续时间的分隔符
						durationDisplay: true, // 显示持续时间
						remainingTimeDisplay: true, // 是否显示剩余时间功能
						fullscreenToggle: true, // 是否显示全屏按钮
					}
				}
				return playOptions
			},
			leftsClick() {
				if (this.showVideoIndex == 0) {

				} else {
					this.showVideoIndex -= 1
				}

			},
			rightsClick() {
				if ((this.showVideoIndex + 1) == this.showVideo.length) {
					// this.showVideoIndex += 1
				} else {
					this.showVideoIndex += 1
				}

			},
			downPoliy(item) {
				ePolicy({
					orderId: item.orderId
				}).then(res => {
					if (res.code == 200) {
						window.open(res.result, '_blank');
					}
					console.log(res)
				})
			},
			// 打开商品详情
			openLine(item) {
				// this.$router.push('/gameDetaile/' + item.gameCommodityId)
				window.open(this.$router.resolve({
					path: '/gameDetaile/' + item.gameCommodityId,

				}).href, '_blank');
			},





		}


	}
</script>
<style scoped lang="scss">
	.notice {
		height: 72px;
		background: #0085FF;
	}

	.chatMain {
		width: 1666px;
		height: 952px;
		background: #F9F9F9;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
		border-radius: 20px 20px 20px 20px;
		overflow: hidden;
		display: flex;
		margin-top: 16px;
		margin-bottom: 16px;
	}

	.chatLeft {
		width: 112px;
		padding-bottom: 40px;
		background: #212328;
		color: #A5A7B1;
		overflow: auto;

		.leftItem {
			height: 112px;
			flex-direction: column;
			justify-content: center;
			cursor: pointer;
		}

		.leftItem1 {
			height: 90px;
			flex-direction: column;
			justify-content: center;
			cursor: pointer;
		}

		.leftText {
			margin-top: 8px;
		}
	}

	.chatLeft::-webkit-scrollbar {
		display: none;
	}

	.account {
		width: 372px;
		height: 100%;
		overflow: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		background-color: #fff;
	}

	.search {
		width: 372px;
		z-index: 99;

		.searchInfo {
			margin-left: 12px;
		}
	}

	.account::-webkit-scrollbar {
		display: none;
	}

	.chatList {
		overflow: auto;
	}

	.chatList::-webkit-scrollbar {
		display: none;
	}

	.chatItem {
		margin-top: 16px;
		position: relative;
		border-radius: 12px;
		cursor: pointer;
		.chatClose {
			position: absolute;
			bottom: 10px;
			right: 10px;
			display: none;
		}
	}
	.chatItem:hover {
		.chatClose {
			position: absolute;
			bottom: 5px;
			right: 10px;
			display: block;
		}
	}

	.infoTitle {
		.infoAccount {
			font-size: 10px;
			background: #FF5C00;
			border-radius: 4px;
			color: #fff;
			padding: 1px 3px;
		}

		font-weight: 600;
		font-size: 14px;
		line-height: 30px;
		color: #000000;
		width: 250px;
	}

	.idItem {
		font-weight: 400;
		font-size: 14px;
		color: #A5A7B1;
		margin-bottom: 5px;
	}

	.chat {
		width: 644px;

		position: relative;

		.chatTop {
			display: flex;

			.chatTopItem {
				width: 157px;
				height: 54px;
				border-radius: 4px;
				border: 1px solid #DEDEDE;
				display: flex;
				align-items: center;
				padding-left: 16px;
				font-weight: 600;
				font-size: 16px;
				color: #0085FF;
			}

			.chatAction {
				background-color: #ECF9FF;
			}
		}
	}

	.chatCenter {
		height: 570px;
		// width: 624px;
		padding-right: 16px;
		overflow: auto;
	}

	// .chatCenter::-webkit-scrollbar {
	// 	display: none;
	// }

	.recordsLeft {
		margin-top: 20px;
		margin-left: 16px;
		display: flex;

	}

	.recordsRight {
		margin-top: 20px;
		margin-left: 16px;
		display: flex;
		justify-content: flex-end;
	}

	.leftInfo {
		margin-left: 8px;

	}

	.rightInfo {

		display: grid;
		justify-items: end;
		/* 水平居中 */
		align-items: center;
		/* 垂直居中 */
		margin-right: 8px;

	}

	.infoName {
		font-weight: 400;
		font-size: 16px;
		color: #0085FF;
	}

	.infoName2 {
		font-weight: 400;
		font-size: 16px;
		color: #FF5C00;
	}


	.infoTime {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
		margin-left: 8px;
	}

	.infoTime1 {
		font-weight: 400;
		font-size: 16px;
		color: #000;
		margin-left: 8px;
	}

	.infoCount {

		word-break: break-all; //会断词
		max-width: 296px;
		display: inline-block;

		background: #F1F1F1;
		border-radius: 8px;
		padding: 12px 16px;
		margin-top: 4px;

	}

	.chatBottom {
		border-top: 1px solid #ECECEC;
		height: 270px;
		position: absolute;
		bottom: 0;
		z-index: 99;
		left: 0;
		width: 608px;
		padding: 18px;
	}

	.bottomTop {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.bottomTips {
			font-weight: 400;
			font-size: 16px;
			color: #FF5C00;
			margin-right: 10px;
		}
	}

	.functionImage {
		width: 28px;
		height: 28px;
		margin-right: 30px;
		cursor: pointer;
	}

	::v-deep .el-textarea__inner {
		border: none;
		margin-top: 10px;
		// width: 580px;
		height: 160px;
		background-color: #F9F9F9;
	}

	.chatArea {
		font-size: 18px;
		// height: 200px;
		color: #000;
	}


	.orderAll {
		background-color: #fff;
		width: 538px;
		overflow: auto;

		.orderTop {
			padding: 16px;

			.orderImage {
				width: 60px;
				height: 60px;
			}

			.orderTitle {
				font-weight: 600;
				font-size: 20px;
				color: #1D223C;
			}

			.orderDetaile {
				font-weight: 400;
				font-size: 16px;
				color: #A5A7B1;
			}

			.orderState {
				display: flex;
				flex-direction: column;
				margin-left: 12px;
			}
		}

		.stateList {
			display: flex;
			justify-content: right;
			align-items: center;
			margin-top: 8px;
			padding-bottom: 8px;
			border-bottom: 1px solid #ECECEC;
			width: 100%;

			span {
				margin-left: 8px;
			}

			.state1 {
				font-weight: 400;
				font-size: 14px;
				color: #FFFFFF;
				height: 22px;
				padding: 4px 12px;
				background: #19D972;
				border-radius: 6px 6px 6px 6px;
				cursor: pointer;
			}

			.state2 {
				font-weight: 400;
				font-size: 14px;
				color: #FFFFFF;
				height: 22px;
				padding: 4px 12px;
				background: #FF5C00;
				border-radius: 6px 6px 6px 6px;
				cursor: pointer;
			}

			.cancelState {
				font-weight: 400;
				font-size: 14px;
				height: 22px;
				color: #777A8A;
				padding: 3px 12px;
				border: 1px solid #DEDEDE;
				border-radius: 6px 6px 6px 6px;
				cursor: pointer;
			}
		}
	}

	.orderAll::-webkit-scrollbar {
		display: none;
	}

	.takeInfo {
		padding: 8px 12px;
		border-bottom: 1px solid #ECECEC;

		.takeTitle {
			font-weight: 600;
			font-size: 16px;
			color: #1D223C;
			line-height: 24px;
			margin-left: 18px;
			position: relative;
		}

		.takeTitle::before {
			position: absolute;
			content: '';
			top: 0;
			left: -15px;
			width: 3px;
			height: 24px;
			background: #FF5C00;

		}

		.takeTitle1 {
			font-weight: 600;
			font-size: 16px;
			color: #1D223C;
			line-height: 24px;
			margin-left: 18px;
			position: relative;
		}

		.takeTitle1::before {
			position: absolute;
			content: '';
			top: 0;
			left: -15px;
			width: 3px;
			height: 24px;
			background: #0085FF;

		}

		.infoItem {
			font-weight: 400;
			font-size: 16px;
			color: #A5A7B1;
			margin-top: 12px;
		}
	}

	.takeSubmit {

		height: 54px;
		background: #FAFAFA;
		border-radius: 6px 6px 6px 6px;
		padding-left: 12px;
		margin-top: 16px;

		.takeText {
			font-weight: 400;
			font-size: 16px;
			color: #A5A7B1;

			line-height: 40px;
		}

		.takeInput {
			width: 225px;
			height: 40px;
			border-radius: 4px 4px 4px 4px;
		}

		.takeBtn {
			width: 56px;
			height: 38px;
			padding: 0;
			margin-left: 8px;
		}
	}

	// ::v-deep .el-timeline-item__node:last-child{
	// 	border: 4px solid #FF5C00;
	// }
	::v-deep .el-timeline {
		margin-left: -40px;
	}

	::v-deep .el-timeline-item__tail {
		border-left: 2px dotted #E4E7ED;

	}

	.infoTitle1 {
		font-weight: 600;
		font-size: 14px;
		line-height: 30px;
		color: #0085FF;
		width: 250px;
		cursor: pointer;
	}

	.idItems {
		font-weight: 400;
		font-size: 14px;
		color: #A5A7B1;
		margin-top: 3px;
	}

	::v-deep .el-table th {
		background: #FAFAFA;
		color: #000;
	}

	.leftAction {
		background: linear-gradient(270deg, rgba(0, 133, 255, 0.3) 0%, rgba(0, 133, 255, 0) 100%);
		color: #0085FF;
	}

	.img-style {
		margin-left: 10px;
		margin-bottom: 10px;
		cursor: pointer;
	}

	.bottomLeft {
		position: relative;

		.emoji {
			position: absolute;
			bottom: 60px;
			left: -100px;
			width: 300px;
			background-color: #fff;
			padding: 15px;
			border-radius: 5px;
			border: 1px solid #E4E7ED;
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
		}

		// el-popover el-popper


	}

	.dialogCenter {
		border-top: 1px solid #FAFAFA;
		border-bottom: 1px solid #FAFAFA;
		flex-direction: column;
		padding: 10px 0;

		.deleteTitle {
			font-weight: 600;
			font-size: 20px;
			color: #1D223C;
			text-align: center;
			margin: 20px;
		}

		.deleteDetaile {
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			text-align: center;
			line-height: 30px;
		}

	}

	.dialogLable {

		font-size: 16px;
		color: #000000;
		width: 100px;
		justify-content: space-between;
		margin-left: 40px;
		position: relative;

	}

	.must {
		position: relative;
	}

	.must::after {
		position: absolute;
		content: '*';
		color: red;
		top: 0;
		left: -20px;
	}

	.dialogValue {
		position: relative;

		::v-deep .el-select-dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}
	}

	.formItem {
		margin-bottom: 20px;

		.submitLable {
			width: 100px;
			text-align: right;

			.lableText {
				font-weight: 400;
				font-size: 18px;
				color: #1D223C;
				line-height: 30px;
				position: relative;
				white-space: nowrap;
			}


		}

		.submitValue {
			margin: 0 20px;
			position: relative;

			.getCode {
				position: absolute;
				color: #0085FF;
				right: 10px;
				top: 10px;
			}

			::v-deep .el-cascader {
				width: 400px;
			}

			::v-deep .el-input__inner {
				width: 300px;
			}

			::v-deep .el-popper {
				position: absolute !important;
				top: auto !important;
				left: auto !important;
			}

			::v-deep .el-select-dropdown {
				position: absolute !important;
				top: auto !important;
				left: auto !important;
			}
		}

		.submitRemark {
			// width: 40%;
			margin-left: 50px;
			color: red;
		}
	}

	.chatStatus {
		position: absolute;
		bottom: 5px;
		left: 0;
		width: 80px;
		text-align: center;
		font-size: 14px;
		color: #FFFFFF;
		opacity: 0.8;
		padding: 3px 0;
		letter-spacing: 2px;
		background-color: rgba(0, 0, 0, .8);
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}

	.chatStatus1 {
		position: absolute;
		top: 10px;
		right: -20px;
		width: 80px;
		text-align: center;
		font-size: 14px;
		color: #FFFFFF;
		background: #FF5C00;
		transform: rotate(45deg);
		// opacity: 0.8;
		letter-spacing: 2px;
		overflow: hidden;
	}

	.formTip {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
		line-height: 21px;
		text-align: left;
		margin-left: 20px;
	}



	.uploadText {
		font-weight: 400;
		font-size: 18px;
		color: #0085FF;
		line-height: 21px;
		margin-top: 10px;
	}

	.uploadTip {
		font-weight: 400;
		font-size: 16px;
		color: #FF5C00;
		// line-height: 30px;

	}

	.reason {
		::v-deep .el-textarea__inner {
			background: none;
			width: 376px;
			border: 1px solid #C0C4CC;
			height: 100px;
		}
	}

	.search {


		.searchText {
			width: 100px;
			text-align: right;
			font-weight: 400;
			font-size: 18px;
			color: #1D223C;
		}
	}

	.roleTitles {

		width: 1186px;
		border-bottom: 1px solid #ECECEC;
		height: 67px;
		// line-height: 87px;
		padding-left: 12px;
		padding-right: 42px;
		margin-top: 30px;
		display: flex;
		align-items: center;

		.titleItem {
			font-weight: 400;
			font-size: 20px;
			position: relative;
			color: #1D223C;
			height: 30px;
			margin-right: 50px;
		}

		.titleItem::after {
			position: absolute;
			width: 1px;
			height: 20px;
			content: '';
			top: 2px;
			right: -25px;
			background-color: #DEDEDE;
		}

		.titleItem:last-child::after {
			width: 0px;
		}

		.action {
			color: #0085FF;
			font-weight: bold;
		}
	}

	.tableList {
		margin: 20px;
		width: 1400px;
		height: 500px;
		overflow-y: auto;
		scrollbar-width: none;
	}

	.popUp {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background-color: rgba(0, 0, 0, .6);
		overflow: hidden;

		.popClose {
			position: absolute;
			top: 20px;
			right: 100px;
			width: 50px;
			height: 50px;
		}

		.popLeft {
			position: absolute;
			top: 450px;
			left: 30px;
			width: 50px;
			height: 50px;
		}

		.popRight {
			position: absolute;
			top: 450px;
			right: 30px;
			width: 50px;
			height: 50px;
		}
	}

	::v-deep .video-js.vjs-fluid {
		height: 100vh;

	}

	.chatActions {
		background-color: #e5f6ff;
	}

	.HandleDrag {
		// background-color: rgba(0, 0, 0, .6);
		// z-index: 9999;
		opacity: .4;
	}

	.payOvertime {
		color: #FF5C00;
		font-size: 20px;
		font-weight: bold;
		position: absolute;
		bottom: -50px;
		left: 0;
		width: 200px;
		text-align: center;
	}

	.textareaSty {
		width: 100%;
		height: 180px;
		resize: none;

		border-radius: 4px;
		overflow-y: auto;
		scrollbar-width: none;

		margin: 5px 0;
		border: none;
		background: none;

	}

	[contenteditable]:focus {
		outline: none;
	}

	.mag50 {
		margin-left: 50px;
	}

	.content {
		margin: 0 auto;
		width: 1600px;
	}
	
	.role{
		::v-deep .el-input__suffix{
			display: none;
		}
		::v-deep .el-input__inner{
			cursor: text;
		}
	}
	.allMore{
		text-align: center;
		color: #0085FF;
		margin-top: 8px;
		cursor: pointer;
	}
</style>